// AT-2102 | AT-2161 | Ankur Makavana | 18-07-2023 | Reports to have to 2 sub-sections: 1. Mobile Reports Desktop 2. MIS Reports Reports Master - to be greyed out for now Finance Reports - to be greyed out for now Business Reports
import React from "react";
import { logout, consolidate, filterData } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import moment from "moment";
import { async } from "q";
import load_img from "../assets/images/report_load.gif";
import customFetch from "./apicall/api";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { DatePicker, Select } from "antd";
const { RangePicker } = DatePicker;
//import "antd/dist/antd.css";

class PBI_report extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      reportList_data: [],
      report_config: [],
      report_linkdata: [],
      isVisible: true,
      isInitial: true,
      loading: false,
      isloaded: 0,
      pin: false,
      drop: false,
      period_val: [],
      report_url: "",
      report_list_data: "",
    };
    this.iframeRef = React.createRef();
  }

  // Ankur Makavana | 18-07-2023 | get report list based on sub categories
  componentDidMount() {
    const { api_token, categories_id } = this.props || {};
    this.setState({ isSpin: true });

    customFetch(
      process.env.REACT_APP_PBI_URL + "/get/reports/list/" + categories_id,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          reportList_data: data,
          isSpin: false,
        });
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  }

  loadreport(e) {
    this.setState({
      report_url:
        process.env.REACT_APP_PBI_URL +
        "/get/report/" +
        this.state.report_list_data,
    });
  }

  render() {
    const { api_token, subcategories_name } =
      (this.props.location && this.props.location.state) || {};
    const { reportList_data } = this.state;
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        {this.state.pin ? (
          <div className="preloader">
            <img src={load_img} />
          </div>
        ) : (
          ""
        )}

        <div className="container">
          <div className=" mb-2 inputholder mt-2">
            {/* {this.state.isVisible && ( */}
            <div id="accordion">
              <div class="">
                <div
                  id="headingOne"
                  className={
                    !this.state.isVisible
                      ? "report_head show_title "
                      : "report_head "
                  }
                  onClick={() => {
                    this.out();
                  }}
                >
                  <h5
                    class="mb-0"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    // aria-expanded="true"
                    // aria-controls="collapseOne"
                    id="colapse"
                  >
                    {!this.state.isInitial &&
                      !this.state.isVisible &&
                      this.state.report_name}
                  </h5>
                  {!this.state.isInitial &&
                    !this.state.isVisible &&
                    (!this.state.drop ? <DownOutlined /> : <UpOutlined />)}
                </div>

                <div
                  id="collapseOne"
                  class={
                    !this.state.isVisible && !this.state.drop
                      ? "collapse"
                      : "collapse show"
                  }
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="">
                    <div className="row">
                      <div className="report_inputs pr-1 mb-1">
                        <Select
                          showSearch
                          className="form-control text-center"
                          onChange={(e) => {
                            this.setState({
                              report_list_data: e,
                              report_url: "",
                            });
                          }}
                          placeholder="Select Report"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option && option.label
                              ? option.label.toLowerCase()
                              : ""
                            ).includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA && optionA.label
                              ? optionA.label.toLowerCase()
                              : ""
                            ).localeCompare(
                              optionB && optionB.label
                                ? optionB.label.toLowerCase()
                                : ""
                            )
                          }
                          options={
                            reportList_data &&
                            reportList_data.map((reportList) => {
                              return {
                                label: reportList.report_name,
                                value:
                                  reportList.report_id +
                                  "~" +
                                  reportList.report_name,
                              };
                            })
                          }
                        />
                      </div>

                      {console.log(
                        "this.state.report_config",
                        this.state.report_config
                      )}

                      {this.state.report_list_data ? (
                        <div className="report_inputs">
                          <button
                            className="btn btn-info form-control"
                            onClick={() => {
                              this.loadreport();
                            }}
                          >
                            Load
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* )} */}
          </div>
          <div className="">
            {console.log("report_url=>", this.state.report_url)}
            {/* {this.state.loading && (
              
            )} */}
            {this.state.report_url && (
              <>
                <div className="mask"></div>
                <iframe
                  src={this.state.report_url}
                  style={{marginTop: "-40px"}}
                  width="100%"
                  height="600"
                  onLoad={this.hideSpinner}
                  ref={this.iframeRef}
                  cache="no"
                  crossorigin="anonymous"
                  Expires="-1"
                ></iframe>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default PBI_report;
