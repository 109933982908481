import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import useCheckMobileScreen from "../hooks/useCheckMobileScreen";

const SupersetDashboardEmbed = ({ supersetId, supersetUrl }) => {
  const supersetIdContainer = useRef(null);
  const isMobile = useCheckMobileScreen();
  // console.log("dashboardId-->", supersetId);
  // const supersetId = "b3be5979-7835-4a51-9e8d-ddb42e6ade06"
  const empID = localStorage.getItem("employee_id");
  const designationID = localStorage.getItem("e_designation_id");

  const supersetUrlContainer = useRef(null);
  const [supersetUrlContainerHeight, setSupersetUrlContainerHeight] = useState('100vh');

  useEffect(() => {
    if (supersetUrlContainer.current) {
      const fromTop = supersetUrlContainer.current.getBoundingClientRect().top;
      setSupersetUrlContainerHeight(`calc(100vh - ${fromTop}px)`);
    }
  }, [supersetUrl]);

  useEffect(() => {
    const dashEmbed = async () => {
      const data = await fetchToken();
      // console.log(data);
      if (data && supersetIdContainer.current) {
        window.supersetEmbeddedSdk.embedDashboard({
          id: supersetId,
          supersetDomain: process.env.REACT_APP_SUPERSET_URL,
          mountPoint: supersetIdContainer.current,
          fetchGuestToken: () => data.token,
          dashboardUiConfig: {
            hideTitle: true,
            filters: {
              expanded: false,
            },
            urlParams: {
              in_emp_id: empID,
              in_des_id: designationID,
            },
          },
        });
        const iframe = supersetIdContainer.current.querySelector("iframe");
        if (iframe) {
          const fromTop = iframe.getBoundingClientRect()
          iframe.style.width = "100%";
          iframe.style.height = `calc(100vh - ${fromTop.top}px)`;
          // adjustZoom();
          // window.addEventListener("resize", adjustZoom);
        }
      }
    };
    
    const reportCatDiv = document.getElementById('reportCategories');
    const reportStyle = reportCatDiv ? reportCatDiv.getAttribute('style') : ''
    if(window.innerWidth <= 768 && reportCatDiv){
      reportCatDiv.setAttribute('style', 'max-width: 100%;')
    }
    
    const metaViewport = document.querySelector('meta[name="viewport"]');
    const originalContent = metaViewport ? metaViewport.getAttribute('content') : '';
    if (metaViewport) {
      metaViewport.setAttribute('content', '');
    }
    if(supersetId){
      dashEmbed();
    }

    return () => {
      // window.removeEventListener("resize", adjustZoom);
      if (metaViewport) {
        metaViewport.setAttribute('content', originalContent || 'width=device-width, initial-scale=1.0');
      }
      if(reportCatDiv){
        reportCatDiv.setAttribute('style', reportStyle)
      }
    }
  }, [supersetIdContainer]);

  const fetchAccessToken = async () => {
    try {
      const body = {
        username: "admin",
        password: "admin",
        provider: "db",
        refresh: true,
      };

      const response = await fetch(
        process.env.REACT_APP_SUPERSET_URL+"api/v1/security/login",
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("access: ", response);

      const jsonResponse = await response.json();
      return jsonResponse.access_token;
    } catch (e) {
      console.error("Error fetching access: ", e);
    }
  };

  const fetchGuestToken = async (accessToken) => {
    try {
      let _body = {
        user: {
          first_name: "Embed",
          last_name: "Embed",
          username: "embed",
        },
        resources: [
          {
            id: supersetId,
            type: "dashboard",
          },
        ],
        rls: [
          // {
          //   "clause": "string",
          //   "dataset": 0
          // }
        ],
      };
      const res = await fetch(
        process.env.REACT_APP_SUPERSET_URL+"api/v1/security/guest_token/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(_body),
        }
      );
      // console.log("guest: ", res);

      const responseJson = await res.json();
      return responseJson;
    } catch (e) {
      console.error("Error fetching guest: ", e);
    }
  };

  const fetchToken = async () => {
    try {
      const accessToken = await fetchAccessToken();
      const guestToken = await fetchGuestToken(accessToken);
      return guestToken;
    } catch (e) {
      console.error(e);
    }
  };

  // const adjustZoom = () => {
  //   if (window.innerWidth < 768) {
  //     const scale = window.innerWidth / 1024;
  //     document.body.style.transform = `scale(${scale})`;
  //     document.body.style.transformOrigin = "0 0";
  //     // document.body.style.maxWidth = 1024
  //     document.body.style.width = `${100 / scale}%`;
  //   } else {
  //     // document.body.style.transform = "";
  //     // document.body.style.width = "";
  //   }
  // };

  return (
    <React.Fragment>
      {/* {isMobile && <div>View in landscape mode</div>} */}
      {(supersetId && supersetId !== "") && (
        <div className="superset-container" ref={supersetIdContainer}></div>
      )}
      {(supersetUrl && supersetUrl !== "") && (
        <iframe ref={supersetUrlContainer} src={supersetUrl} style={{width: "100%", height: supersetUrlContainerHeight}}></iframe>
      )}
    </React.Fragment>
  );
};

export default withRouter(SupersetDashboardEmbed);
