import React, { Component } from "react";
import customFetch from "./apicall/api";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { logout, chkapiinput, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import { PEButton, PEDiv, PEImg, PEInput, PEA, PESpan } from "./permissionComponents";
const generate_Id1 = generateId(10);
class CashAcceptanceTO_denom extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      numbers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      amounts: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      total: 0,
      remark: "",
      isRejectBtn:true,
      responseMessage:"",
      editInput: [true, true, true, true, true, true, true, true, true, true],
      buttonInput: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      acceptInput: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      opening_balance: 0,
      cash_balance_id: props.location.state.cash_balance_id,
      submit: true,
      user_id: props.location.state.user_id,
      isOpen: false,
      isErrOpen: false,
      api_error_message: "",
      isSpin: false,
      
    };
  }

  fetchDetails = () => {
    this.setState({ isSpin: true });
    /*Check if data is null then logout*/
    var arr = [this.state.user_id, this.state.cash_balance_id];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }

    const { api_token } =
      (this.props.location && this.props.location.state) || {};

    const params = {
      in_userid: this.state.user_id,
    };

    if (this.state.cash_balance_id != null) {
      params["cash_balance_id"] = this.state.cash_balance_id;
    }

    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    //const response = '';
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "CashAcceptanceTO_denom",
        user_id: localStorage.getItem("in_userid"),
      },
    };

    customFetch(
      process.env.REACT_APP_URL + "/get/denominations?" + queryParams,
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({ remark: "" }, () => {
          console.log(this.state.remark, "remark");
        });

        let _opening_balance = parseInt(data["opening_balance"]);
        let _cash_balance_id = data["cash_balance_id"];
        data = data["denomination"];

        let _numbers = this.state.numbers;
        let _amounts = this.state.amounts;
        const _recon_key = data['recon_key']
        for (let index = 0; index < data.length; index++) {
          let number = data[index]["qty_submitted"];
          let value = data[index]["currency_value"];

          //console.log(value + " " + number);
          if (value == "2000") {
            _numbers[0] = number;
            _amounts[0] = number * 2000;
          } else if (value == "500") {
            _numbers[1] = number;
            _amounts[1] = number * 500;
          } else if (value == "200") {
            _numbers[2] = number;
            _amounts[2] = number * 200;
          } else if (value == "100") {
            _numbers[3] = number;
            _amounts[3] = number * 100;
          } else if (value == "50") {
            _numbers[4] = number;
            _amounts[4] = number * 50;
          } else if (value == "20") {
            _numbers[5] = number;
            _amounts[5] = number * 20;
          } else if (value == "10") {
            _numbers[6] = number;
            _amounts[6] = number * 10;
          } else if (value == "5") {
            _numbers[7] = number;
            _amounts[7] = number * 5;
          } else if (value == "2") {
            _numbers[8] = number;
            _amounts[8] = number * 2;
          } else if (value == "1") {
            _numbers[9] = number;
            _amounts[9] = number * 1;
          }
        }
        let _total = 0;
        for (let i = 0; i < 10; i++) _total += _amounts[i];
        this.setState({
          numbers: _numbers,
          amounts: _amounts,
          total: _total,
          opening_balance: _opening_balance,
          cash_balance_id: _cash_balance_id,
          isSpin: false,
          recon_key : _recon_key
        });
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false
        })
      });
  };

  isSubmittable = () => {
    for (let i = 0; i < this.state.acceptInput.length; i++) {
      if (this.state.acceptInput[i] == false) return false;
    }
    return true;
  };

  handleInputChange = (event) => {
    event.preventDefault();

    let number = Number(event.target.value);

    if (isNaN(number) || !isFinite(number)) {
      number = 0;
      event.target.value = 0;
      return;
    }

    let _numbers = this.state.numbers;
    let _amounts = this.state.amounts;
    if (event.target.name == "2000") {
      _numbers[0] = number;
      _amounts[0] = number * 2000;
    } else if (event.target.name == "500") {
      _numbers[1] = number;
      _amounts[1] = number * 500;
    } else if (event.target.name == "200") {
      _numbers[2] = number;
      _amounts[2] = number * 200;
    } else if (event.target.name == "100") {
      _numbers[3] = number;
      _amounts[3] = number * 100;
    } else if (event.target.name == "50") {
      _numbers[4] = number;
      _amounts[4] = number * 50;
    } else if (event.target.name == "20") {
      _numbers[5] = number;
      _amounts[5] = number * 20;
    } else if (event.target.name == "10") {
      _numbers[6] = number;
      _amounts[6] = number * 10;
    } else if (event.target.name == "5") {
      _numbers[7] = number;
      _amounts[7] = number * 5;
    } else if (event.target.name == "2") {
      _numbers[8] = number;
      _amounts[8] = number * 2;
    } else if (event.target.name == "1") {
      _numbers[9] = number;
      _amounts[9] = number * 1;
    }
    let _total = 0;
    for (let i = 0; i < 10; i++) _total += _amounts[i];

    this.setState({ numbers: _numbers, amounts: _amounts, total: _total });
  };

  handleAcceptButton = (event) => {
    let name = event.target.name;

    let _edit = this.state.editInput;
    let _accept = this.state.acceptInput;
    if (name == "2000") {
      _edit[0] = true;
      _accept[0] = true;
    } else if (name == "500") {
      _edit[1] = true;
      _accept[1] = true;
    } else if (name == "200") {
      _edit[2] = true;
      _accept[2] = true;
    } else if (name == "100") {
      _edit[3] = true;
      _accept[3] = true;
    } else if (name == "50") {
      _edit[4] = true;
      _accept[4] = true;
    } else if (name == "20") {
      _edit[5] = true;
      _accept[5] = true;
    } else if (name == "10") {
      _edit[6] = true;
      _accept[6] = true;
    } else if (name == "5") {
      _edit[7] = true;
      _accept[7] = true;
    } else if (name == "2") {
      _edit[8] = true;
      _accept[8] = true;
    } else if (name == "1") {
      _edit[9] = true;
      _accept[9] = true;
    }

    this.setState({
      editInput: _edit,
      acceptInput: _accept,
      submit: this.isSubmittable(),
    });
  };

  handleEditButton = (event) => {
    let name = event.target.name;

    let _edit = this.state.editInput;
    let _accept = this.state.acceptInput;

    if (name == "2000") {
      _edit[0] = false;
      _accept[0] = false;
    } else if (name == "500") {
      _edit[1] = false;
      _accept[1] = false;
    } else if (name == "200") {
      _edit[2] = false;
      _accept[2] = false;
    } else if (name == "100") {
      _edit[3] = false;
      _accept[3] = false;
    } else if (name == "50") {
      _edit[4] = false;
      _accept[4] = false;
    } else if (name == "20") {
      _edit[5] = false;
      _accept[5] = false;
    } else if (name == "10") {
      _edit[6] = false;
      _accept[6] = false;
    } else if (name == "5") {
      _edit[7] = false;
      _accept[7] = false;
    } else if (name == "2") {
      _edit[8] = false;
      _accept[8] = false;
    } else if (name == "1") {
      _edit[9] = false;
      _accept[9] = false;
    }

    this.setState({
      editInput: _edit,
      acceptInput: _accept,
      submit: this.isSubmittable(),
    });
  };

  handleSubmit = (event) => {
    if (this.state.remark.length <= 10) {
      this.setState({
        api_error_message: "remark must be more than 10 charecters",
        submit: true,
        isErrOpen: true
      });
      return 0;
    }
    
    this.setState({ isSpin: true });
    
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    //event.preventDefault();
    //this.setState({submit: this.isSubmittable()});
    let denominations = {};
    let _numbers = this.state.numbers;
    const uid = localStorage.getItem("in_userid");
    for (let i = _numbers.length; i >= 0; i--) {
      // if(_numbers[i] <= 0)
      //   continue;
      if (i == 0) denominations["2000"] = _numbers[i];
      else if (i == 1) denominations["500"] = _numbers[i];
      else if (i == 2) denominations["200"] = _numbers[i];
      else if (i == 3) denominations["100"] = _numbers[i];
      else if (i == 4) denominations["50"] = _numbers[i];
      else if (i == 5) denominations["20"] = _numbers[i];
      else if (i == 6) denominations["10"] = _numbers[i];
      else if (i == 7) denominations["5"] = _numbers[i];
      else if (i == 8) denominations["2"] = _numbers[i];
      else if (i == 9) denominations["1"] = _numbers[i];
    }
    /*Check if data is null then logout*/
    var arr = [this.state.user_id, this.state.total, uid];
    var chkdata = chkapiinput(arr);
    if (chkdata === false) {
      this.setState({ isSpin: false });
      setTimeout(function() {
        logout();
      }, 2000);
    }
    let pay_load = {
      in_userid: this.state.user_id,
      status: "03",
      denomination_details: denominations,
      total_amt: this.state.total,
      remark: this.state.remark,
      approved_by: uid,
      txn_type: "C",
    };

    if (this.state.cash_balance_id != null)
      pay_load["cash_balance_id"] = this.state.cash_balance_id;

    let _body = JSON.stringify(pay_load);
    let generate_Id = generateId(10);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-token": api_token,
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "CashAcceptanceTO_denom",
        user_id: localStorage.getItem("in_userid"),
      },
      body: _body,
    };
    console.log(_body);

    customFetch(
      process.env.REACT_APP_URL + "/save/denominations",
      requestOptions
    )
      .then((response) => {
        if (response.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: response.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        //this.setState({cash_balance_id: data['cash_balance_id']});
        //this.props.history.goBack();
        //this.fetchDetails();
        this.setState({ isOpen: true, isSpin: false,responseMessage:"Transaction Successfully Submitted!!!" });
      })
      .catch((error) => {
        //this.setState({submit: this.isSubmittable()});
        console.log("error", error);
        this.setState({
          isSpin: false
        })
      });
  };

  chkreject = () => {
    if (this.state.remark.length <= 10) {
      this.setState({
        api_error_message: "remark must be more than 10 charecters",
        submit: true,
        isRejectBtn: true,
        isErrOpen: true
      });
    } else {
      this.handleReject();
    }
  };

  handleReject = () => {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    this.setState({
      isSpin: true,
    });
    
    const uid = localStorage.getItem("in_userid");

    let payload = {
      in_userid: uid,
      status: "04",
      approved_by: uid,
      txn_type: "C",// C for Collection
      recon_key: this.state.rkey,
      remark: this.state.remark
    };

    payload["deposit_slip_verified"] = this.state.dep_slip;
    payload["deposit_amount_verified"] = this.state.dep_amt;

    if (this.state.cash_balance_id != null)
      payload["cash_balance_id"] = this.state.cash_balance_id;

    let _body = JSON.stringify(payload);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-token": api_token,
      },
      body: _body,
    };
    console.log(_body);

    customFetch(process.env.REACT_APP_URL + "/save/denominations", requestOptions)
      .then((response) => {
          if(response.status!=200){
            response.json().then((json)=>{
              console.log(json.message)
            })
            throw new Error('Internal Server Error')
          }
          return response.json()
      })
      .then((data) => {
        if (data.api_code === 4) {
          localStorage.clear();
          this.props.history.push("/");
        } else {
          this.setState({
            isOpen: true,
            isRejectBtn: true,
            responseMessage: "Transaction rejected successfully!!!",
            isSpin: false,
          })
        }
      })
      .catch((error)=>{
        this.setState({
          isErrOpen: true,
          isRejectBtn: true,
          api_error_message: error.message,
          isSpin: false,
        })
      });
  };
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }

  componentDidMount = () => {
    document.title = "Cash Acceptance Denomination";
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: "CashAcceptanceTO_denom",
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    this.fetchDetails();
  };

  handleRemarkChange = (event) => {
    const pattern = /[^0-9a-zA-Z. ]/g;
    const result = pattern.test(event.target.value);
    //console.log("Email Valid:", result)
    if (result === false) {
      //this.setState({ errormsg: "", isEnable: false });
      this.setState({ remark: event.target.value });
    }
  };
  render() {
    const { isOpen, isErrOpen, api_error_message } = this.state;
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <div
          className={
            isOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isOpen: false, submit: false });
                  this.props.history.goBack();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <span>{this.state.responseMessage}</span>
              <button
                data-dismiss="modal"
                onClick={() => {
                  this.setState({ isOpen: false, submit: false });
                  this.props.history.goBack();
                }}
                className="btn btn-primary btn-lg btn-block rbtn search_elements"
              >
                OK
              </button>
            </div>
          </div>
        </div>

        <div className="cash-acceptance-denom">
          <div className="container d-flex flex-column align-items-center">
            <div className="ca-dn-card d-flex flex-column align-items-center">
              <div className="collection_block_header">
                <div className="title-holder_bg">
                  <div
                    className="back_icon_bg"
                    onClick={() => this.props.history.goBack()}
                  >
                    <img
                      src={require("../assets/images/back.png")}
                      className="back_icon_img"
                    />
                  </div>
                  <h2>Cash Acceptance Screen</h2>
                  <a>
                    <div className="log-out-deposit">
                      <img
                        src={require("../assets/images/power-off.png")}
                        onClick={() => logout()}
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="dn-container mt-3">
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-header-text">Denom</div>
                  <div className="dn-row-box dn-header-text">Number</div>
                  <div className="dn-row-box dn-header-text">Amount</div>
                  {/* <div className="dn-row-box dn-header-text"></div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">2000</div>
                  <input
                    name="2000"
                    disabled={this.state.editInput[0]}
                    type="text"
                    onChange={this.handleInputChange}
                    value={this.state.numbers[0]}
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[0].toLocaleString()}
                  </div>
                  {/* <div className="dn-row-box fn-button">
                    <a
                      name="2000"
                      style={
                        this.state.acceptInput[0]
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                      onClick={this.handleAcceptButton}
                    >
                      <img
                        name="2000"
                        src={require("../assets/images/accept.png")}
                        alt=">"
                      />
                    </a>
                    <a name="2000" onClick={this.handleEditButton}>
                      <img
                        name="2000"
                        src={require("../assets/images/remove.png")}
                        alt="X"
                      />
                    </a>
                  </div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">500</div>
                  <input
                    name="500"
                    disabled={this.state.editInput[1]}
                    type="text"
                    onChange={this.handleInputChange}
                    value={this.state.numbers[1]}
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[1].toLocaleString()}
                  </div>
                  {/* <div className="dn-row-box fn-button">
                    <a
                      name="500"
                      style={
                        this.state.acceptInput[1]
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                      onClick={this.handleAcceptButton}
                    >
                      <img
                        name="500"
                        src={require("../assets/images/accept.png")}
                        alt=">"
                      />
                    </a>
                    <a name="500" onClick={this.handleEditButton}>
                      <img
                        name="500"
                        src={require("../assets/images/remove.png")}
                        alt="X"
                      />
                    </a>
                  </div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">200</div>
                  <input
                    name="200"
                    disabled={this.state.editInput[2]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[2]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[2].toLocaleString()}
                  </div>
                  {/* <div className="dn-row-box fn-button">
                    <a
                      name="200"
                      style={
                        this.state.acceptInput[2]
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                      onClick={this.handleAcceptButton}
                    >
                      <img
                        name="200"
                        src={require("../assets/images/accept.png")}
                        alt=">"
                      />
                    </a>
                    <a name="200" onClick={this.handleEditButton}>
                      <img
                        name="200"
                        src={require("../assets/images/remove.png")}
                        alt="X"
                      />
                    </a>
                  </div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">100</div>
                  <input
                    name="100"
                    disabled={this.state.editInput[3]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[3]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[3].toLocaleString()}
                  </div>
                  {/* <div className="dn-row-box fn-button">
                    <a
                      name="100"
                      style={
                        this.state.acceptInput[3]
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                      onClick={this.handleAcceptButton}
                    >
                      <img
                        name="100"
                        src={require("../assets/images/accept.png")}
                        alt=">"
                      />
                    </a>
                    <a name="100" onClick={this.handleEditButton}>
                      <img
                        name="100"
                        src={require("../assets/images/remove.png")}
                        alt="X"
                      />
                    </a>
                  </div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">50</div>
                  <input
                    name="50"
                    disabled={this.state.editInput[4]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[4]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[4].toLocaleString()}
                  </div>
                  {/* <div className="dn-row-box fn-button">
                    <a
                      name="50"
                      style={
                        this.state.acceptInput[4]
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                      onClick={this.handleAcceptButton}
                    >
                      <img
                        name="50"
                        src={require("../assets/images/accept.png")}
                        alt=">"
                      />
                    </a>
                    <a name="50" onClick={this.handleEditButton}>
                      <img
                        name="50"
                        src={require("../assets/images/remove.png")}
                        alt="X"
                      />
                    </a>
                  </div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">20</div>
                  <input
                    name="20"
                    disabled={this.state.editInput[5]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[5]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[5].toLocaleString()}
                  </div>
                  {/* <div className="dn-row-box fn-button">
                    <a
                      name="20"
                      style={
                        this.state.acceptInput[5]
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                      onClick={this.handleAcceptButton}
                    >
                      <img
                        name="20"
                        src={require("../assets/images/accept.png")}
                        alt=">"
                      />
                    </a>
                    <a name="20" onClick={this.handleEditButton}>
                      <img
                        name="20"
                        src={require("../assets/images/remove.png")}
                        alt="X"
                      />
                    </a>
                  </div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">10</div>
                  <input
                    name="10"
                    disabled={this.state.editInput[6]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[6]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[6].toLocaleString()}
                  </div>
                  {/* <div className="dn-row-box fn-button">
                    <a
                      name="10"
                      style={
                        this.state.acceptInput[6]
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                      onClick={this.handleAcceptButton}
                    >
                      <img
                        name="10"
                        src={require("../assets/images/accept.png")}
                        alt=">"
                      />
                    </a>
                    <a name="10" onClick={this.handleEditButton}>
                      <img
                        name="10"
                        src={require("../assets/images/remove.png")}
                        alt="X"
                      />
                    </a>
                  </div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">5</div>
                  <input
                    name="5"
                    disabled={this.state.editInput[7]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[7]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[7].toLocaleString()}
                  </div>
                  {/* <div className="dn-row-box fn-button">
                    <a
                      name="5"
                      style={
                        this.state.acceptInput[7]
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                      onClick={this.handleAcceptButton}
                    >
                      <img
                        name="5"
                        src={require("../assets/images/accept.png")}
                        alt=">"
                      />
                    </a>
                    <a name="5" onClick={this.handleEditButton}>
                      <img
                        name="5"
                        src={require("../assets/images/remove.png")}
                        alt="X"
                      />
                    </a>
                  </div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">2</div>
                  <input
                    name="2"
                    disabled={this.state.editInput[8]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[8]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[8].toLocaleString()}
                  </div>
                  {/* <div className="dn-row-box fn-button">
                    <a
                      name="2"
                      style={
                        this.state.acceptInput[8]
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                      onClick={this.handleAcceptButton}
                    >
                      <img
                        name="2"
                        src={require("../assets/images/accept.png")}
                        alt=">"
                      />
                    </a>
                    <a name="2" onClick={this.handleEditButton}>
                      <img
                        name="2"
                        src={require("../assets/images/remove.png")}
                        alt="X"
                      />
                    </a>
                  </div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box dn-gre-amt">1</div>
                  <input
                    name="1"
                    disabled={this.state.editInput[9]}
                    onChange={this.handleInputChange}
                    value={this.state.numbers[9]}
                    type="text"
                    className="dn-row-box dn-red-amt"
                  />
                  <div className="dn-row-box dn-pri-amt">
                    {this.state.amounts[9].toLocaleString()}
                  </div>
                  {/* <div className="dn-row-box fn-button">
                    <a
                      name="1"
                      style={
                        this.state.acceptInput[9]
                          ? { opacity: 0.5 }
                          : { opacity: 1 }
                      }
                      onClick={this.handleAcceptButton}
                    >
                      <img
                        name="1"
                        src={require("../assets/images/accept.png")}
                        alt=">"
                      />
                    </a>
                    <a name="1" onClick={this.handleEditButton}>
                      <img
                        name="1"
                        src={require("../assets/images/remove.png")}
                        alt="X"
                      />
                    </a>
                  </div> */}
                </div>
                <div className="dn-row d-flex flex-row justify-content-between align-items-center">
                  <div className="dn-row-box"></div>
                  <div className=" dn-total dn-header-text">
                    Rs. {this.state.total.toLocaleString()}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  {/* <div>Remarks :</div> */}
                  <textarea
                    type="text"
                    name="remark"
                    onChange={this.handleRemarkChange}
                    placeholder="Please enter your remarks"
                    value={this.state.remark}
                    maxlength="50"
                    className="remarks-inp"
                  ></textarea>
                </div>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',gap:'20px'}}>
             <PEButton element_id="CashRejectBtn"
                disabled={!this.state.isRejectBtn}
                onClick={() => {
                  this.setState({ isRejectBtn: false });
                  this.chkreject();
                }}
                data-toggle="modal"
                data-target="#submit-modal"
                className="ca-submit-btn btn btn-primary"
              >
                Reject
              </PEButton>
              <PEButton element_id="CashSubmitBtn"
                disabled={!this.state.submit}
                onClick={() => {
                  this.setState({ submit: false });
                  this.handleSubmit();
                }}
                data-toggle="modal"
                data-target="#submit-modal"
                className="ca-submit-btn btn btn-primary"
              >
                Submit
              </PEButton>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CashAcceptanceTO_denom;
