import React from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { NormalizeData } from "./utility/NormalizeData";

const CustomDownload = ({ data }) => {
  const token = localStorage.getItem("api_token");

  // Format current date for file naming
  const date = new Date();
  const formattedDate = date.toLocaleString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }).replace(/[/, ]/g, '_').replace(/:/g, '');

  console.log("Received data in CustomDownload component:", data);
  if (!data) {
    console.error("No data received");
    return null;
  }

  const files = NormalizeData(data);
  console.log("Normalized files:", files);

  const handleDownload = async () => {
    if (!files || files.length === 0 || files.some(file => !file.path)) {
      console.error("No valid files to download");
      return;
    }

    const downloadFile = async (filePath) => {
      const formdata = new FormData();
      formdata.append("blob_url", filePath);

      const requestOptions = {
        method: "POST",
        headers: {
          "api-token": token,
        },
        body: formdata,
        mode: "cors"
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/download/files/bucket`,
          requestOptions
        );
        if (!response.ok) {
          const apiError = response.headers.get("api_error_message") || "Download failed";
          throw new Error(apiError);
        }
        return response.blob();
      } catch (error) {
        console.error("Error fetching file:", error);
        throw error;
      }
    };

    try {
      if (files.length === 1) {
        const fileBlob = await downloadFile(files[0].path);
        const url = window.URL.createObjectURL(fileBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", files[0].name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        const zip = new JSZip();
        const downloadPromises = files.map(async (file) => {
          const fileBlob = await downloadFile(file.path);
          zip.file(file.name, fileBlob);
        });

        await Promise.all(downloadPromises);
        const zipBlob = await zip.generateAsync({ type: "blob" });
        saveAs(zipBlob, `files_${formattedDate}.zip`);
      }
    } catch (error) {
      console.error("Error downloading files:", error);
    }
  };

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <button onClick={handleDownload}>Download</button>
    </div>
  );
};

export default CustomDownload;