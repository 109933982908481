import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { logout, chkapiinput } from "./Utility";
import { ThemeContext } from "../context/IsLoader";

class Division extends Component {
  static contextType = ThemeContext;
  constructor() {
    super();
    this.state = {
      divisiondata: [],
      isLoaded: false,
      isErrOpen: false,
      api_error_message: "",
      isSpin: false,
    };
    this.doThis = this.doThis.bind(this);
  }
  componentDidMount() {
    document.title = "Division";
    //AT-1659 | AT-1767 | Ankur Makavana | 05-07-2023 | store ui performance data
    // setTimeout(() => {
    //   if (document.readyState === "complete") {
    //     this.context.onPageLoad();
    //   } else {
    //     window.addEventListener("load", this.context.onPageLoad());
    //     this.cleanup = () =>
    //       window.removeEventListener("load", this.context.onPageLoad());
    //   }
    // }, 1000);
    this.setState({ isSpin: true });
    const uid = localStorage.getItem("in_userid");
    if (!uid) {
      localStorage.clear();
      this.props.history.push("/");
    }
    const { api_token, entity_id } =
      (this.props.location && this.props.location.state) || {};
    if (entity_id) {
      /*Check if data is null then logout*/
      var arr = [uid, entity_id];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function() {
          logout();
        }, 2000);
      }
      /*Check if data is null then logout End*/
      const formdata = new FormData();
      formdata.append("in_userid", uid);
      formdata.append("in_entity_id", entity_id);
      customFetch(process.env.REACT_APP_URL + "/getreport", {
        method: "POST",
        headers: { "api-token": api_token, payload_id: this.context.payloadId },
        body: formdata,
      })
        .then((res) => {
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((json) => {
          console.log(this.state.divisiondata);
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isLoaded: true,
            divisiondata: json,
            isSpin: false,
          });
        })
        .catch((error) => console.log("error", error));
    } else {
      /*Check if data is null then logout*/
      var arr = [uid];
      var chkdata = chkapiinput(arr);
      if (chkdata === false) {
        this.setState({ isSpin: false });
        setTimeout(function() {
          logout();
        }, 2000);
      }
      /*Check if data is null then logout End*/
      const formdata = new FormData();
      formdata.append("in_userid", uid);
      formdata.append("call_procedure", uid);
      customFetch(process.env.REACT_APP_URL + "/getreport", {
        method: "POST",
        headers: { "api-token": api_token, payload_id: this.context.payloadId },
        body: formdata,
      })
        .then((res) => {
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((json) => {
          console.log(this.state.divisiondata);
          if (
            json.api_code === 4 ||
            json.api_code === 3 ||
            json.api_code === 5
          ) {
            localStorage.clear();
            this.props.history.push("/");
          }
          this.setState({
            isLoaded: true,
            divisiondata: json,
            isSpin: false,
          });
        })
        .catch((error) => console.log("error", error));
    }
  }
  totalbalance() {
    const { divisiondata } = this.state;
    if (divisiondata.length > 0) {
      const total = divisiondata.reduce(
        (prevValue, currentValue) =>
          parseFloat(prevValue) + parseFloat(currentValue.balance),
        0
      );
      return total.toLocaleString("en-IN");
    } else {
      const total = 0;
      return total;
    }
  }
  totaldemand() {
    const { divisiondata } = this.state;
    if (divisiondata.length > 0) {
      const total = divisiondata.reduce(
        (prevValue, currentValue) =>
          parseFloat(prevValue) + parseFloat(currentValue.demand),
        0
      );
      return total.toLocaleString("en-IN");
    } else {
      const total = 0;
      return total;
    }
  }
  totalcollect() {
    const { divisiondata } = this.state;
    if (divisiondata.length > 0) {
      const total = divisiondata.reduce(
        (prevValue, currentValue) =>
          parseFloat(prevValue) + parseFloat(currentValue.collection),
        0
      );
      return total.toLocaleString("en-IN");
    } else {
      const total = 0;
      return total;
    }
  }
  doThis(entity_id, entity_name) {
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    this.props.history.push({
      pathname: "/Cluster",
      state: {
        entity_id: entity_id,
        entity_name: entity_name,
        api_token: api_token,
      },
    });
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  render() {
    const { entity_name } =
      (this.props.location && this.props.location.state) || {};
    const { divisiondata, isErrOpen, api_error_message } = this.state;
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="log-out-report">
            <img
              src={require("../assets/images/power-off.png")}
              onClick={() => logout()}
              alt=""
            />
          </div>
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="icon_img"
                  alt=""
                />
              </div>
              <div className="menu-image-holder" style={{ display: "none" }}>
                <a href="#">
                  <img
                    src={require("../assets/images/menu.png")}
                    alt=""
                    className="menu-image"
                  />
                </a>
              </div>
              <div className="head-title">View Reports</div>
              <div className="menu-image-holder2" style={{ display: "none" }}>
                <a href="#">
                  <img
                    src={require("../assets/images/cloud-download.png")}
                    alt=""
                    className="menu-image2"
                  />
                </a>
              </div>
              <div className="menu-image-holder3" style={{ display: "none" }}>
                <a href="#">
                  <img
                    src={require("../assets/images/hierarchy-64.png")}
                    alt=""
                    className="menu-image3"
                  />
                </a>
              </div>
            </div>
            <div className="title-holder">
              <h2>DEMAND SHEET DIVISION</h2>
            </div>
            <div className="main-area">
              <div className="name-holder">
                <h2>{entity_name}</h2>
              </div>
              <div className="demand-holder">
                <h2>Demand</h2>
                <span>Rs. {this.totaldemand()}</span>
              </div>
              <div className="main-foor-part mt-3 mb-2">
                <div className="collected-holder">
                  <h2>Collected</h2>
                  <span>Rs. {this.totalcollect()}</span>
                </div>
                <div className="balance-holder">
                  <h2>Balance</h2>
                  <span>Rs. {this.totalbalance()}</span>
                </div>
              </div>
            </div>
            <div className="report-card-holder">
              {divisiondata.map((item) => (
                <div
                  className="card card-border mt-3"
                  key={item.entity_id}
                  onClick={() => this.doThis(item.entity_id, item.entity_name)}
                >
                  <div className="card-body">
                    <div className="report-card-title">
                      <h3>{item.entity_name}</h3>
                    </div>
                    <div className="report-card-subhead">
                      <span className="subhead">Demand</span>
                      <span className="subamount">
                        Rs. {parseFloat(item.demand).toLocaleString("en-IN")}
                      </span>
                    </div>
                    <div className="report-card-subhead">
                      <span className="subheadone">Collected</span>
                      <span className="subamount">
                        Rs.{" "}
                        {parseFloat(item.collection).toLocaleString("en-IN")}
                      </span>
                    </div>
                    <div className="report-card-subhead">
                      <span className="subheadthree">Balance</span>
                      <span className="subamount">
                        Rs. {parseFloat(item.balance).toLocaleString("en-IN")}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Division);
