import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { logout, navtime, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import Cookies from "js-cookie";

const generate_Id1 = generateId(10);
class Login extends Component {
  static contextType = ThemeContext;
  constructor() {
    super();
    this.state = {
      show: false,
      IPv4: null,
      latitude: null,
      longitude: null,
      email: "",
      username: "",
      phone: "",
      password: "",
      otp: "",
      errormsg: "",
      successmsg: "",
      errormsgphone: "",
      errormsgpassword: "",
      isEnable: true,
      isEnablePhone: true,
      canResend: false,
      isSpin: false,
      //changes
      flag:0,
      //
      time: {
        minutes: 0,
        seconds: 0,
      },
      duration: 10 * 60 * 1000,
      timer: null,
      isErrOpen: false,
      api_error_message: "",
      isViewpass: true,
      passread: true,
      exiuid: "",
      login_status: "",
      isLoginstatus: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlemailSubmit = this.handlemailSubmit.bind(this);
    this.handlephoneSubmit = this.handlephoneSubmit.bind(this);
    this.getAccesstoDevrev = this.getAccesstoDevrev.bind(this);
    this.startTimer = this.start.bind(this);
    this.setResendButtonTimeout = null;
  }

  componentDidMount() {
    document.title = "Login";
    // let flag = 0; // Default value

    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });
        
        if (tokenData.userid != "" && tokenData.access_token != "") {
          localStorage.setItem("in_userid", tokenData.userid);
          localStorage.setItem("api_token", tokenData.access_token);
          localStorage.setItem("access_token", tokenData.access_token);
          localStorage.setItem("session_id", tokenData.session_id);
          localStorage.setItem("em_branch_id", tokenData.em_branch_id);
          this.props.history.push({
            pathname: "/Dashboard",
            state: {
              api_token: tokenData.access_token,
              password_age: 1,
            },
          });
        }
        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
      // Handle the error gracefully
      /*if (localStorage.getItem("access_token")) {
        fetch(process.env.REACT_APP_URL + "/new/logout", {
          method: "POST",
          headers: { "api-token": localStorage.getItem("access_token") },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data.userid);
          })
          .catch((error) => {
            console.log(error);
          });
      }*/
    }
    console.log("Flag value:", this.state.flag);
    //AT-1659 | AT-1767 | Ankur Makavana | 05-07-2023 | store ui performance data    if (localStorage.getItem("access_token")) {
    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: "Login",
          component_id: generate_Id1,
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const { payloadId } = this.context;
    console.log("payloadId", this.context.payloadId);
    // navigator.geolocation.getCurrentPosition(
    //   function(position) {
    //     this.setState({
    //       latitude: position.coords.latitude,
    //       longitude: position.coords.longitude,
    //     });
    //   }.bind(this)
    // );
    fetch("https://ipapi.co/json/")
      .then((response) => {
        return response.json();
      }, "jsonp")
      .then((res) => {
        console.log(res);
        this.context.setip(res.ip);
        this.setState({
          IPv4: res.ip,
          latitude: res.latitude,
          longitude: res.longitude,
        });
      })
      .catch((err) => console.log(err));
  }
  msToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);

    minutes = minutes.toString().padStart(2, "0");
    seconds = seconds.toString().padStart(2, "0");

    return {
      minutes,
      seconds,
    };
  }
  start() {
    if (!this.state.timer) {
      this.state.startTime = Date.now();
      this.timer = window.setInterval(() => this.run(), 10);
    }
  }
  run() {
    const diff = Date.now() - this.state.startTime;

    let remaining = this.state.duration - diff;
    if (remaining < 0) {
      remaining = 0;
    }
    this.setState(() => ({
      time: this.msToTime(remaining),
    }));
    if (remaining === 0) {
      window.clearTimeout(this.timer);
      this.timer = null;
      this.setState({ canResend: true });
    }
  }
  // resetTimer = () => {
  //   if(this.setResendButtonTimeout !== null) {
  //    this.setState({canResend:false})
  //     clearTimeout(this.setResendButtonTimeout)
  //   }
  //   this.setResendButtonTimeout = setTimeout(() => {
  //     this.setState({canResend:true})
  //   },2000)
  // }
  handleClick() {
    this.setState({
      show: !this.state.show,
    });
  }
  handleResendButtonClick = (e) => {
    e && e.preventDefault();
    //this.resetTimer()
    this.setState({ canResend: false, isSpin: true });
    this.start();
    let generate_Id = generateId(10);
    const formdata1 = new FormData();
    formdata1.append("user_email", this.state.email);
    customFetch(process.env.REACT_APP_URL + "/delotp", {
      method: "POST",
      headers: {
        payload_id: this.context.payloadId,
      },
      body: formdata1,
      headers: {
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "Login",
      },
    })
      .then((res1) => {
        if (res1.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res1.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res1.json();
      })
      .then((data1) => {
        console.log(data1.sendotp);
        if (data1.sendotp === true) {
          const formdata2 = new FormData();
          formdata2.append("user_email", this.state.email);
          let generate_Id = generateId(10);
          customFetch(process.env.REACT_APP_URL + "/sendotp", {
            method: "POST",
            headers: {
              payload_id: this.context.payloadId,
            },
            body: formdata2,
            headers: {
              component_id: generate_Id1,
              apiId: generate_Id,
              api_group_id: "",
              component: "Login",
            },
          })
            .then((res2) => {
              if (res2.headers.get("api_code") > 0) {
                this.setState({
                  isErrOpen: true,
                  api_error_message: res2.headers.get("api_error_message"),
                  isSpin: false,
                });
              }
              return res2.json();
            })
            .then((data2) => {
              console.log(data2.sendotp);
              if (data2.sendotp === true) {
                this.setState({ isSpin: false });
                this.setState({ successmsg: "OTP Send Success!" });
                //this.start()
                alert("OTP Send Success!");
              }
            })
            .catch((error) => {
              console.log("error", error)
              this.setState({
                isSpin: false,
              });
            });
        }
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  };

  handlephoneSubmit(event) {
    this.setState({ errormsg: "", isSpin: true });
    this.setState({ successmsg: "" });
    const { phone } = this.state;
    event.preventDefault();
    const params = {
      in_phone_no: phone,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    customFetch(process.env.REACT_APP_URL + "/userexist?" + queryParams, {
      method: "GET",
      headers: {
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "Login",
      },
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((data) => {
        console.log(data.userid);
        if (data.Valid === true) {
          const formdata1 = new FormData();
          formdata1.append("user_mobile_no", phone);
          let generate_Id = generateId(10);
          customFetch(process.env.REACT_APP_URL + "/delotp", {
            method: "POST",
            headers: {
              payload_id: this.context.payloadId,
            },
            body: formdata1,
            headers: {
              component_id: generate_Id1,
              apiId: generate_Id,
              api_group_id: "",
              component: "Login",
            },
          })
            .then((res1) => {
              if (res1.headers.get("api_code") > 0) {
                this.setState({
                  isErrOpen: true,
                  api_error_message: res1.headers.get("api_error_message"),
                  isSpin: false,
                });
              }
              return res1.json();
            })
            .then((data1) => {
              console.log(data1.sendotp);
              if (data1.sendotp === true) {
                const formdata2 = new FormData();
                formdata2.append("user_mobile_no", phone);
                let generate_Id = generateId(10);
                customFetch(process.env.REACT_APP_URL + "/sendotp", {
                  method: "POST",
                  headers: {
                    payload_id: this.context.payloadId,
                  },
                  body: formdata2,
                  headers: {
                    component_id: generate_Id1,
                    apiId: generate_Id,
                    api_group_id: "",
                    component: "Login",
                  },
                })
                  .then((res2) => {
                    if (res2.headers.get("api_code") > 0) {
                      this.setState({
                        isErrOpen: true,
                        api_error_message: res2.headers.get(
                          "api_error_message"
                        ),
                        isSpin: false,
                      });
                    }
                    return res2.json();
                  })
                  .then((data2) => {
                    console.log(data2.sendotp);
                    if (data2.sendotp === true) {
                      console.log("OTP Send");
                      this.setState({
                        successmsg: "OTP Send Success!",
                        isSpin: false,
                      });
                      alert("OTP Send Success!");
                      //  localStorage.setItem('in_userid', data2.in_userid);
                      //  localStorage.setItem('in_username', data2.in_username);
                      this.props.history.push({
                        pathname: "/Phoneotp",
                        state: {
                          phone: phone,
                          latitude: this.state.latitude,
                          longitude: this.state.longitude,
                          userid: data.userid,
                        },
                      });
                    }
                  })
                  .catch((error) => {
                    console.log("error", error)
                    this.setState({
                      isSpin: false,
                    });
                  });
                }
              })
            .catch((error) => {
              console.log("error", error)
              this.setState({
                isSpin: false,
              });
            });
        } else {
          this.setState({ errormsgphone: "User Not Found", isSpin: false });
        }
      })
      .catch((error) => {
          console.log("error", error)
          this.setState({
            isSpin: false,
          });
        });
  }
  handleSlideChange() {
    console.log("Slider Change");
    console.log(this.state.show);
    if (this.state.show === false) {
      this.setState({ errormsg: "", isSpin: true });
      this.setState({ successmsg: "" });
      const { username } = this.state;
      //event.preventDefault()
      // const formdata = new FormData();
      // formdata.append("in_username", username);
      const params = {
        in_username: username,
      };
      const queryParams = new URLSearchParams(params).toString();
      let generate_Id = generateId(10);
      customFetch(process.env.REACT_APP_URL + "/userexist?" + queryParams, {
        method: "GET",
        headers: {
          component_id: generate_Id1,
          apiId: generate_Id,
          api_group_id: "",
          component: "Login",
        },
      })
        .then((res) => {
          if (res.headers.get("api_code") > 0) {
            this.setState({
              isErrOpen: true,
              api_error_message: res.headers.get("api_error_message"),
              isSpin: false,
            });
          }
          return res.json();
        })
        .then((data) => {
          //console.log(data.userid);
          if (data.Valid === true) {
            this.setState({ email: data.useremail });
            const formdata1 = new FormData();
            formdata1.append("user_email", data.useremail);
            let generate_Id = generateId(10);
            customFetch(process.env.REACT_APP_URL + "/delotp", {
              method: "POST",
              headers: {
                payload_id: this.context.payloadId,
              },
              body: formdata1,
              headers: {
                component_id: generate_Id1,
                apiId: generate_Id,
                api_group_id: "",
                component: "Login",
              },
            })
              .then((res1) => {
                if (res1.headers.get("api_code") > 0) {
                  this.setState({
                    isErrOpen: true,
                    api_error_message: res1.headers.get("api_error_message"),
                    isSpin: false,
                  });
                }
                return res1.json();
              })
              .then((data1) => {
                console.log(data1.sendotp);
                if (data1.sendotp === true) {
                  const formdata2 = new FormData();
                  formdata2.append("user_email", data.useremail);
                  let generate_Id = generateId(10);
                  customFetch(process.env.REACT_APP_URL + "/sendotp", {
                    method: "POST",
                    headers: {
                      payload_id: this.context.payloadId,
                    },
                    body: formdata2,
                    headers: {
                      component_id: generate_Id1,
                      apiId: generate_Id,
                      api_group_id: "",
                      component: "Login",
                    },
                  })
                    .then((res2) => {
                      if (res2.headers.get("api_code") > 0) {
                        this.setState({
                          isErrOpen: true,
                          api_error_message: res2.headers.get(
                            "api_error_message"
                          ),
                          isSpin: false,
                        });
                      }
                      return res2.json();
                    })
                    .then((data2) => {
                      console.log(data2.sendotp);
                      if (data2.sendotp === true) {
                        this.setState({
                          successmsg: "OTP Send Success!",
                          isSpin: false,
                        });
                        this.start();
                        alert("OTP Send Success!");
                      }
                    })
                    .catch((error) => {
                      console.log("error", error)
                      this.setState({
                        isSpin: false,
                      });
                    });
                }
              })
              .catch((error) => {
                console.log("error", error)
                this.setState({
                  isSpin: false,
                });
              });
          } else {
            this.setState({
              errormsg: "User Not Found",
              isEnable: true,
              isSpin: false,
            });
          }
        })
        .catch((error) => {
          console.log("error", error)
          this.setState({
            isSpin: false,
          });
        });
    }
  }
  handlemailSubmit(event) {
    console.log("Form submit");
    let generate_Id = generateId(10);
    // this.context.onPageLoad({
    //   component: "Login",
    //   url: process.env.REACT_APP_URL + "/login",
    //   method: "POST",
    //   data: formdata2,
    //   component_id: generate_Id1,
    //   apiId: generate_Id,
    //   api_group_id: "",
    // });
    this.setState({
      isSpin: true,
      isEnable: true,
      errormsg: "",
      successmsg: "",
    });
    const { email, password, otp, show, username } = this.state;
    event.preventDefault();
    const ftoken = localStorage.getItem("currentToken");
    const formdata2 = new FormData();
    //formdata2.append("in_username", username);
    formdata2.append("in_userid", localStorage.getItem("userid"));
    formdata2.append("in_user_password", password);
    formdata2.append("in_client_ip", this.state.IPv4);
    formdata2.append("in_latitude", this.state.latitude);
    formdata2.append("in_longitude", this.state.longitude);
    formdata2.append(
      "in_gps_address",
      this.state.latitude + "," + this.state.longitude
    );
    if (otp !== "") {
      formdata2.append("otp_source", email);
      formdata2.append("otp", otp);
    }
    formdata2.append("in_userid", this.state.exiuid);
    formdata2.append("firebase_token", ftoken ? ftoken : "");
    formdata2.append("app_id", process.env.REACT_APP_APP_ID)
    customFetch(process.env.REACT_APP_URL + "/new/login", {
      method: "POST",
      headers: {
        payload_id: this.context.payloadId,
      },
      body: formdata2,
      headers: {
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "Login",
      },
    })
      .then((res2) => {
        if (res2.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res2.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res2.json();
      })
      .then((data2) => {
        console.log("data2->", data2);
        if (data2.logged_in === true) {
          const { setcontextuser_id } = this.context;
          setcontextuser_id(data2.in_userid);
          localStorage.setItem("in_userid", data2.in_userid);
          localStorage.setItem("contextuser_id", data2.in_userid);
          localStorage.setItem("in_username", data2.in_username);
          localStorage.setItem("phone_no", data2.phone_no);
          localStorage.setItem("employee_name", data2.employee_name);
          localStorage.setItem("api_token", data2.access_token);
          localStorage.setItem("access_token", data2.access_token);
          localStorage.setItem("refresh_token", data2.refresh_token);
          Cookies.set("refresh_token", data2.refresh_token);

          this.getAccesstoDevrev()

          const params = {
            userid: data2.in_userid,
          };
          const queryParams = new URLSearchParams(params).toString();
          let generate_Id = generateId(10);
          customFetch(process.env.REACT_APP_URL + "/getrole?" + queryParams, {
            method: "GET",
            headers: {
              "api-token": data2.access_token,
              component_id: generate_Id1,
              apiId: generate_Id,
              api_group_id: "",
              component: this.constructor.name,
              user_id: localStorage.getItem("in_userid"),
            },
          })
            .then((res) => {
              if (res.headers.get("api_code") > 0) {
                this.setState({
                  isErrOpen: true,
                  api_error_message: res.headers.get("api_error_message"),
                  isSpin: false,
                });
              }
              return res.json();
            })
            .then((data) => {
              if (
                data.api_code === 4 ||
                data.api_code === 3 ||
                data.api_code === 5
              ) {
                localStorage.clear();
                this.props.history.push("/");
              }
              localStorage.setItem("employee_id", data.employee_id);
              localStorage.setItem("branch_id", data.branch_id);
              localStorage.setItem("mapped_branch_id", data.mapped_branch_id);
              localStorage.setItem("designation_name", data.designation_name);
              localStorage.setItem(
                "contextuser_branchid",
                data.mapped_branch_id
              );
              localStorage.setItem(
                "e_designation_id",
                data.effective_designation_id
              );
              localStorage.setItem("designation_name", data.designation_name);
              localStorage.setItem("em_branch_id", data.em_branch_id);
              localStorage.setItem("branch_name", data.branch_name);
              if (data.effective_designation_id) {
                console.log(
                  "e_designation_id",
                  localStorage.getItem("e_designation_id")
                );
                this.props.history.push({
                  pathname: "/Dashboard",
                  state: {
                    api_token: data2.access_token,
                    password_age: data2.password_age,
                  },
                });
              }
            })
            .catch((error) => {
              console.log("error", error)
              this.setState({
                isSpin: false,
              });
            });

          const uid = localStorage.getItem("in_userid");
        } else {
          localStorage.setItem("access_token", data2.access_token);
          if (data2.login_status == "LI") {
            this.setState({
              isLoginstatus: true,
            });
          }
          this.setState({
            errormsgpassword: data2.action_message,
            login_status: data2.login_status,
            isSpin: false,
            isEnable: false,
          });
        }
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  }

  getAccesstoDevrev() {
    const requestOptions = {
      method: "GET",
      headers: {
        "api-token": localStorage.getItem("api_token"),
      },
    };
    customFetch(
      process.env.REACT_APP_URL +
        "/get/configuration/parameter/master?parameter_name=devrev_react_fieldops",
      requestOptions
    )
      .then((response) => {
        return response.json();
      }).then((data) => {
        console.log('devrev data', data)
        Cookies.set('parameter_value', data.parameter_value, { expires: 7 });
      })
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "username") {
      const pattern = /((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i;
      const result = pattern.test(event.target.value);
      console.log("Email Valid:", result);
      if (result === true) {
        this.setState({ errormsg: "", isEnable: false });
      } else {
        this.setState({ errormsg: "Enter valid User Name", isEnable: true });
      }
    }
    if (event.target.name === "phone") {
      const pattern = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
      if (pattern.test(event.target.value)) {
        if (event.target.value.length === 10) {
          this.setState({ errormsgphone: "", isEnablePhone: false });
        } else {
          this.setState({
            errormsgphone: "Enter valid Phone Number",
            isEnablePhone: true,
          });
        }
      } else {
        this.setState({
          errormsgphone: "Enter valid Phone Number",
          isEnablePhone: true,
        });
      }
      //console.log("Email Valid:", result)
    }
  }
  viewpass() {
    this.setState({ isViewpass: !this.state.isViewpass });
  }
  handleBlur = (event) => {
    console.log("Form submit");
    this.setState({
      isSpin: true,
    });
    this.setState({ errormsg: "" });
    this.setState({ successmsg: "" });
    const { email, password, otp, show, username } = this.state;
    event.preventDefault();
    const params = {
      in_username: username,
    };
    const queryParams = new URLSearchParams(params).toString();
    let generate_Id = generateId(10);
    console.log("generate_Id", generate_Id);
    // this.context.onPageLoad({
    //   component: "Login",
    //   url: process.env.REACT_APP_URL + "/userexist?" + queryParams,
    //   method: "GET",
    //   data: "",
    //   component_id: generate_Id1,
    //   apiId: generate_Id,
    //   api_group_id: "",
    // });
    customFetch(process.env.REACT_APP_URL + "/userexist?" + queryParams, {
      method: "GET",
      headers: {
        component_id: generate_Id1,
        apiId: generate_Id,
        api_group_id: "",
        component: "Login",
      },
    })
      .then((res) => {
        if (res.headers.get("api_code") > 0) {
          this.setState({
            isErrOpen: true,
            api_error_message: res.headers.get("api_error_message"),
            isSpin: false,
          });
        }
        return res.json();
      })
      .then((data) => {
        console.log(data.userid);
        localStorage.setItem("userid", data.userid);
        if (data.Valid === true) {
          this.setState({
            isSpin: false,
            passread: false,
            isEnable: false,
            exiuid: data.userid,
          });
        } else {
          this.setState({
            errormsg: "User Not Found.",
            isSpin: false,
            isEnable: false,
            exiuid: "",
          });
        }
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  };

  clearlogin() {
    customFetch(process.env.REACT_APP_URL + "/new/logout", {
      method: "POST",
      headers: { "api-token": localStorage.getItem("access_token") },
    })
      .then((res) => res.json())
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    const { isViewpass, isErrOpen, api_error_message } = this.state;
    var beforeload = new Date().getTime();
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div
          className={
            this.state.isLoginstatus
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isLoginstatus: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{this.state.errormsgpassword}</p>
              <span
                className="btn btn-danger col-md-2"
                onClick={() => this.clearlogin()}
              >
                Logout
              </span>
            </div>
          </div>
        </div>

        <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="logo-area">
              <img src={require("../assets/images/aceN Logo.png")} alt="" />
            </div>
            <div className="login-text">
              <div className="login-title">
                <h2>Login Account</h2>
              </div>
              <div className="login-subtitle">
                Hello, welcome back to our account
              </div>
            </div>
            <div className="login-form-holder">
              <ul className="nav nav-pills justify-content-center">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    href="#email-login-area"
                    data-toggle="tab"
                    role="tab"
                  >
                    User Name
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#phone-login-area"
                    data-toggle="tab"
                    role="tab"
                  >
                    Phone Number
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  className="tab-pane active"
                  id="email-login-area"
                  role="tabpanel"
                >
                  <div className="container">
                    <div className="email-login">
                      <form onSubmit={this.handlemailSubmit}>
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="email-label"
                          >
                            User Name
                          </label>
                          <input
                            name="username"
                            placeholder="User Name"
                            className="form-control email-box"
                            value={this.state.username}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                          />
                          <span className="email-error">
                            {this.state.errormsg}
                          </span>
                        </div>
                        <div className="form-group text-center">
                          <label className="p-1">Password</label>{" "}
                          <label className="switch">
                            <input
                              type="checkbox"
                              id="switch"
                              className="toggle_switch"
                              disabled={this.state.isEnable}
                              onClick={() => this.handleClick()}
                              onChange={() => this.handleSlideChange()}
                            />
                            <label className="checkbox-label" htmlFor="switch">
                              Toggle
                            </label>
                          </label>{" "}
                          <label className="p-1">Email OTP</label>
                        </div>

                        <div className="form-group">
                          {!this.state.show && (
                            // <input type="password" className="btn btn-lg btn-primary email-submit" id="exampleInputPassword1" placeholder="Password"/>
                            <>
                              <input
                                {...(isViewpass === true
                                  ? { type: "password" }
                                  : { type: "text" })}
                                name="password"
                                placeholder="Password"
                                className="form-control password-box"
                                value={this.state.password}
                                readOnly={this.state.passread}
                                onChange={this.handleChange}
                              />
                              {isViewpass}
                              <img
                                src={require("../assets/images/view.png")}
                                className="view-pass"
                                onClick={() => this.viewpass()}
                              />
                              {this.state.login_status != "LI" && (
                                <span className="email-error">
                                  {this.state.errormsgpassword}
                                </span>
                              )}
                            </>
                          )}
                          {this.state.show && (
                            <>
                              <input
                                name="otp"
                                placeholder="Email OTP"
                                className="form-control email-box"
                                value={this.state.otp}
                                onChange={this.handleChange}
                              />
                              <div className="text-center">
                                <button
                                  className="btn btn-lg btn-primary email-submit"
                                  onClick={this.handleResendButtonClick}
                                  disabled={!this.state.canResend}
                                >
                                  {!this.state.canResend
                                    ? this.state.time.minutes +
                                      ":" +
                                      this.state.time.seconds
                                    : "Resend OTP"}
                                </button>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="text-center">
                          <input
                            type="submit"
                            className="btn btn-lg btn-primary email-submit"
                            disabled={this.state.isEnable}
                            value="Login"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="phone-login-area" role="tabpanel">
                  <div className="container">
                    <div className="email-login">
                      <form onSubmit={this.handlephoneSubmit}>
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail11"
                            className="email-label"
                          >
                            Phone Number
                          </label>
                          <input
                            name="phone"
                            placeholder="Phone Number"
                            className="form-control email-box"
                            value={this.state.phone}
                            onChange={this.handleChange}
                          />
                          <span className="email-error">
                            {this.state.errormsgphone}
                          </span>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary otp-btn form-control"
                            disabled={this.state.isEnablePhone}
                          >
                            Request OTP
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="navtime">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-clock-history"
                viewBox="0 0 16 16"
              >
                <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
              </svg>
              {navtime(beforeload)}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Login);