import React from "react";
import { requestForToken } from "./firebase";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SupportTicketForm from "./components/SupportTicketForm";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Phone_OTP_Verify from "./components/Phone_OTP_Verify";
import Zone from "./components/Zone";
import Division from "./components/Division";
import Cluster from "./components/Cluster";
import Branch from "./components/Branch";
import Pm from "./components/Pm";
import Cso from "./components/Cso";
import CsoDemandsheet from "./components/CsoDemandsheet";
import Cso_transaction_history from "./components/Cso_transaction_history";
import Deposit_money from "./components/Deposit_money";
import SearchHousehold from "./components/SearchHousehold";
import CollectionReceipt from "./components/CollectionReceipt";
import CashAcceptanceTO_main from "./components/CashAcceptanceTO_main";
import PaymentTransfer from "./components/PaymentTransfer";
import PaymentTransfer_Loan from "./components/PaymentTransfer_Loan";
import CashAcceptanceTO_denom from "./components/CashAcceptanceTO_denom";
import collectionSuccess from "./components/collectionSuccess";
import CashDepositeBank from "./components/CashDepositeBank";
import Profile from "./components/Profile";
import Nach_type from "./components/Nach_type";
import Physical_NACH_register from "./components/Physical_NACH_register";
import Test from "./components/Test";
import Test_two from "./components/Test_two";
import Nach_success from "./components/Nach_success";
import PayloadView from "./components/PayloadView";

import IdleTimeout from "./components/IdleTimeout";
import { ThemeContextProvider } from "./context/IsLoader";

import "./assets/css/main.css";
import CallListCSO from "./components/CallListCSO";
import SupportTicketList from "./components/SupportTicketList";
import SupportTicket from "./components/SupportTicket";
import Reversal from "./components/Reversal";
import TransactionList from "./components/TransactionList";
import ReversalDashboard from "./components/ReversalDashboard";
import SubmitReversal from "./components/SubmitReversal";
import ReversalDetail from "./components/ReversalDetail";
import NACHRegisterBank from "./components/NACHRegisterBank";
import Overnight_request from "./components/Overnight_request";
import Cash_balance_report from "./components/Cash_balance_report";
import PayloadViewSelect from "./components/PayloadViewSelect";
import UTR from "./components/UTR";
import Esign from "./components/Esign";
import ChequeUpload from "./components/ChequeUpload";
import Cheque from "./components/Cheque";
import App_download from "./components/App_download";
import Report from "./components/Report";
import Report_categories from "./components/Report_categories";
import Report_list from "./components/Report_list";
import Mobile_report from "./components/Mobile_report";
// import Esign1 from "./components/Esign1";
import Group_esign from "./components/Group_esign";
import Esign_card from "./components/Esign_card";
import { PermissionsProvider } from "./components/permissionComponents/PermissionContext";
// import ChangePassword from "./components/ChangePassword";
import Other_collection from "./components/Other_collection";
import FilesDownload from "./components/FilesDownload";

import Payments from "./components/Payments";
require("dotenv").config();
//import Notification from './Notification';
function App() {
  //console.log("API URL",process.env.REACT_APP_URL);
  // const uid = localStorage.getItem("in_userid");
  // if(!uid){
  //   localStorage.clear();
  //   this.props.history.push('/')
  // }
  // window.onpopstate = function () {
  //   window.location.reload();
  // };

  requestForToken();
  return (
    <>
      <Router basename="/">
        <React.StrictMode>
          <PermissionsProvider>
            <ThemeContextProvider>
              <IdleTimeout></IdleTimeout>
              <Switch>
                <Route
                  exact
                  path="/reversaldetail"
                  component={ReversalDetail}
                />
                <Route
                  exact
                  path="/submitreversal"
                  component={SubmitReversal}
                />
                <Route
                  exact
                  path="/reversaldashboard"
                  component={ReversalDashboard}
                />
                <Route exact path="/reversals" component={Reversal} />               
                
              <Route
                  exact
                  path="/Other_collection"
                  component={Other_collection}
                />
                <Route
                  exact
                  path="/transactionlist"
                  component={TransactionList}
                />
                <Route exact path="/ticketdetails" component={SupportTicket} />
                <Route
                  exact
                  path="/supportticketlist"
                  component={SupportTicketList}
                />
                <Route
                  exact
                  path="/createsupportticket"
                  component={SupportTicketForm}
                />
                <Route exact path="/calldetails" component={CallListCSO} />
                <Route exact path="/dashboard" component={Dashboard} />
                {/* <Route exact path="/change-password" component={ChangePassword} /> */}
                <Route exact path="/Phoneotp" component={Phone_OTP_Verify} />
                <Route exact path="/Zone" component={Zone} />
                <Route exact path="/Division" component={Division} />
                <Route exact path="/Cluster" component={Cluster} />
                <Route exact path="/Branch" component={Branch} />
                <Route path="/Pm" component={Pm} />
                <Route exact path="/Cso" component={Cso} />
                <Route
                  exact
                  path="/CsoDemandsheet"
                  component={CsoDemandsheet}
                />
                <Route
                  exact
                  path="/Cso_transaction_history"
                  component={Cso_transaction_history}
                />
                <Route exact path="/Deposit_money" component={Deposit_money} />
                <Route
                  exact
                  path="/SearchHousehold"
                  component={SearchHousehold}
                />
                <Route
                  exact
                  path="/CashAcceptanceTO"
                  component={CashAcceptanceTO_main}
                />
                <Route
                  exact
                  path="/cashacceptancedenom"
                  component={CashAcceptanceTO_denom}
                />
                <Route
                  exact
                  path="/CollectionReceipt"
                  component={CollectionReceipt}
                />
                <Route
                  exact
                  path="/PaymentTransfer"
                  component={PaymentTransfer}
                />
                <Route
                  exact
                  path="/PaymentTransfer_Loan"
                  component={PaymentTransfer_Loan}
                />
                <Route
                  exact
                  path="/collectionSuccess"
                  component={collectionSuccess}
                />
                <Route
                  exact
                  path="/CashDepositeBank"
                  component={CashDepositeBank}
                />
                <Route exact path="/Profile" component={Profile} />
                <Route
                  exact
                  path="/Overnightrequest"
                  component={Overnight_request}
                />
                <Route exact path="/Nach_type" component={Nach_type} />
                <Route
                  exact
                  path="/Physical_NACH_register"
                  component={Physical_NACH_register}
                />
                <Route exact path="/Nach_success" component={Nach_success} />
                <Route
                  exact
                  path="/Cash_balance_report"
                  component={Cash_balance_report}
                />

                <Route exact path="/UTR" component={UTR} />
                <Route exact path="/cheque_upload" component={ChequeUpload} />
                <Route exact path="/cheque" component={Cheque} />
                <Route exact path="/Test" component={Test} />
                <Route exact path="/FilesDownload" component={FilesDownload} />
                <Route exact path="/Test_two" component={Test_two} />
                <Route exact path="/" component={Login} />

                <Route
                  exact
                  path="/RegisterNachBank"
                  component={NACHRegisterBank}
                />
                <Route exact path="/payload/view" component={PayloadView} />
                <Route exact path="/payload" component={PayloadViewSelect} />
                <Route exact path="/Esign" component={Esign} />
                {/* <Route exact path="/Esign1" component={Esign1} /> */}
                <Route exact path="/App_download" component={App_download} />
                <Route exact path="/Group_esign" component={Group_esign} />
                <Route exact path="/Report" component={Report} />
                <Route exact path="/Report_list" component={Report_list} />
                <Route exact path="/Mobile_report" component={Mobile_report} />
                <Route exact path="/Esign_card" component={Esign_card} />
                <Route
                  exact
                  path="/Report_categories"
                  component={Report_categories}
                />
                <Route exact path="/payments" component={Payments} />
              </Switch>
            </ThemeContextProvider>
          </PermissionsProvider>
        </React.StrictMode>
      </Router>
    </>
  );
}

export default App;
