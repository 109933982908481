import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import ChatSupportBtn from "./ChatSupportBtn";
import { logout, chkapiinput } from "./Utility";

class Nach_type extends Component {
  constructor() {
    super();
    this.state = { isSpin: false };
  }
  componentDidMount() {
    document.title = "Nach Type";
  }
  // logout() {
  //   localStorage.clear();
  //   this.props.history.push("/");
  // }
  render() {
    // console.log(this.props.location.state);
    const { isOrderid, orderSuccess } = this.state;
    const {
      api_token,
      household_id,
      loan_id,
      map_id,
      prevloans,
      nach_customer_id,
      nach_order_id,
      nach_status_type_flag,
      nach_status_type_description,
      filename,
      loan_documentation_id,
      name_as_per_account,
      bankacno,
      ifsc,
      account_type_name,
    } = (this.props.location && this.props.location.state) || {};
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            isOrderid
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isOrderid: false });
                  window.location.reload();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{orderSuccess}</p>
              <p>Please Reachout To Fin-Ops</p>
            </div>
          </div>
        </div>
        <section className="main-sec">
          <div className="container fix-width">
            <div className="header-area">
              <div
                className="icon_bg"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../assets/images/back.png")}
                  className="cso_icon_img"
                  alt=""
                />
              </div>
              <div className="head-title">NACH Type</div>
              <div className="log-out-report">
                <img
                  src={require("../assets/images/power-off.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            </div>
            <div className="dashboard-card-holder">
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={(e) => {
                  this.setState({ isSpin: true });
                  e.stopPropagation();
                  /*Check if data is null then logout*/
                  var arr = [
                    loan_id,
                    bankacno,
                    ifsc,
                    name_as_per_account,
                    account_type_name,
                  ];
                  var chkdata = chkapiinput(arr);
                  if (chkdata === false) {
                    this.setState({ isSpin: false });
                    setTimeout(function () {
                      logout();
                    }, 2000);
                  }
                  const formdata = new FormData();

                  formdata.append("loan_id", loan_id);
                  formdata.append("nach_type", "P");
                  // if (ntype !== "P") {
                  //   formdata.append("nach_type", "E");
                  //   formdata.append("auth_type", ntype);
                  // } else {
                  //   formdata.append("nach_type", "P");
                  // }
                  formdata.append("map_id", map_id);
                  formdata.append("bank_acc_no", bankacno.trim());
                  formdata.append("ifsc_code", ifsc.trim());
                  formdata.append(
                    "beneficiary_name",
                    name_as_per_account.toLowerCase().trim()
                  );
                  formdata.append(
                    "acc_type",
                    account_type_name.toLowerCase().trim()
                  );
                  customFetch(process.env.REACT_APP_URL + "/set/nach/details", {
                    method: "POST",
                    headers: { "api-token": api_token },
                    body: formdata,
                  })
                    .then((res) => {
                      // if (res.headers.get("api_code") > 0) {
                      //   this.setState({
                      //     isErrOpen: true,
                      //     api_error_message: res.headers.get("api_error_message"),
                      //   });
                      // }
                      // console.log(res.json());
                      return res.json();
                    })

                    .then((json) => {
                      if (
                        json.api_code === 4 ||
                        json.api_code === 3 ||
                        json.api_code === 5
                      ) {
                        localStorage.clear();
                        this.props.history.push("/");
                      }
                      if (json.order_id) {
                        this.setState({
                          orderSuccess: "Your Orderid: " + json.order_id,
                          isOrderid: true,
                          isSpin: false,
                          // isOrderidnull: false,
                        });
                      } else {
                        this.setState({
                          orderSuccess: json.message,
                          isOrderid: true,
                          isSpin: false,
                          // isOrderidnull: false,
                        });
                      }
                    })
                    .catch((error) => {
                      console.log("error", error)
                      this.setState({
                        isSpin: false,
                      });
                    });
                  // this.props.history.push({
                  //   pathname: "/Physical_NACH_register",
                  //   state: {
                  //     api_token: api_token,
                  //     household_id: household_id,
                  //     ntype: "P",
                  //     loan_id: loan_id,
                  //     map_id: map_id,
                  //     prevloans: prevloans,
                  //     nach_customer_id: nach_customer_id,
                  //     nach_order_id: nach_order_id,
                  //     nach_status_type_flag: nach_status_type_flag,
                  //     nach_status_type_description:nach_status_type_description,
                  //     filename:filename,
                  //     loan_documentation_id:loan_documentation_id,
                  //   },
                  // });
                }}
              >
                <div className="card-body webkitcenter">
                  <div className="pnachimage-holder">
                    <img src={require("../assets/images/writing.png")} alt="" />
                  </div>
                  <div className="card-heading">
                    <h2>Physical Nach</h2>
                  </div>
                  {/* <div className="card-subheading">
                        <h2>Monitor your investments & business<br/>performance with dashboards</h2>
                      </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={(e) => {
                  this.setState({ isSpin: true });
                  e.stopPropagation();
                  /*Check if data is null then logout*/
                  var arr = [
                    loan_id,
                    bankacno,
                    ifsc,
                    name_as_per_account,
                    account_type_name,
                  ];
                  var chkdata = chkapiinput(
                    loan_id,
                    bankacno,
                    ifsc,
                    name_as_per_account,
                    account_type_name
                  );
                  if (chkdata === false) {
                    this.setState({ isSpin: false });
                    setTimeout(function () {
                      logout();
                    }, 2000);
                  }
                  const formdata = new FormData();
                  formdata.append("map_id", map_id);
                  formdata.append("loan_id", loan_id);
                  formdata.append("nach_type", "E");
                  formdata.append("auth_type", "N");
                  formdata.append("bank_acc_no", bankacno.trim());
                  formdata.append("ifsc_code", ifsc.trim());
                  formdata.append(
                    "beneficiary_name",
                    name_as_per_account.toLowerCase().trim()
                  );
                  formdata.append(
                    "acc_type",
                    account_type_name.toLowerCase().trim()
                  );
                  customFetch(process.env.REACT_APP_URL + "/set/nach/details", {
                    method: "POST",
                    headers: { "api-token": api_token },
                    body: formdata,
                  })
                    .then((res) => {
                      // if (res.headers.get("api_code") > 0) {
                      //   this.setState({
                      //     isErrOpen: true,
                      //     api_error_message: res.headers.get("api_error_message"),
                      //   });
                      // }
                      // console.log(res.json());
                      return res.json();
                    })

                    .then((json) => {
                      if (
                        json.api_code === 4 ||
                        json.api_code === 3 ||
                        json.api_code === 5
                      ) {
                        localStorage.clear();
                        this.props.history.push("/");
                      }
                      if (json.order_id) {
                        this.setState({
                          orderSuccess: "Your Orderid: " + json.order_id,
                          isOrderid: true,
                          isSpin: false,
                          // isOrderidnull: false,
                        });
                      } else {
                        this.setState({
                          orderSuccess: json.message,
                          isOrderid: true,
                          isSpin: false,
                          // isOrderidnull: false,
                        });
                      }
                    })
                    .catch((error) => {
                      console.log("error", error)
                      this.setState({
                        isSpin: false,
                      });
                    });
                }}
              >
                <div className="card-body webkitcenter">
                  <div className="nbnachimage-holder">
                    <img
                      src={require("../assets/images/online-banking.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>Net Banking eNach</h2>
                  </div>
                  {/* <div className="card-subheading">
                        <h2>Monitor your investments & business<br/>performance with dashboards</h2>
                      </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
              <div
                className="card card-border-small col-sm-6 py-2"
                onClick={(e) => {
                  this.setState({ isSpin: true });
                  e.stopPropagation();
                  /*Check if data is null then logout*/
                  var arr = [
                    loan_id,
                    bankacno,
                    ifsc,
                    name_as_per_account,
                    account_type_name,
                  ];
                  var chkdata = chkapiinput(arr);
                  if (chkdata === false) {
                    this.setState({ isSpin: false });
                    setTimeout(function () {
                      logout();
                    }, 2000);
                  }
                  const formdata = new FormData();
                  formdata.append("map_id", map_id);
                  formdata.append("loan_id", loan_id);
                  formdata.append("nach_type", "E");
                  formdata.append("auth_type", "D");
                  formdata.append("bank_acc_no", bankacno.trim());
                  formdata.append("ifsc_code", ifsc.trim());
                  formdata.append(
                    "beneficiary_name",
                    name_as_per_account.toLowerCase().trim()
                  );
                  formdata.append(
                    "acc_type",
                    account_type_name.toLowerCase().trim()
                  );
                  customFetch(process.env.REACT_APP_URL + "/set/nach/details", {
                    method: "POST",
                    headers: { "api-token": api_token },
                    body: formdata,
                  })
                    .then((res) => {
                      // if (res.headers.get("api_code") > 0) {
                      //   this.setState({
                      //     isErrOpen: true,
                      //     api_error_message: res.headers.get("api_error_message"),
                      //   });
                      // }
                      // console.log(res.json());
                      return res.json();
                    })

                    .then((json) => {
                      if (
                        json.api_code === 4 ||
                        json.api_code === 3 ||
                        json.api_code === 5
                      ) {
                        localStorage.clear();
                        this.props.history.push("/");
                      }
                      if (json.order_id) {
                        this.setState({
                          orderSuccess: "Your Orderid: " + json.order_id,
                          isOrderid: true,
                          isSpin: false,
                          // isOrderidnull: false,
                        });
                      } else {
                        this.setState({
                          orderSuccess:json.message,
                          isOrderid: true,
                          isSpin: false,
                          // isOrderidnull: false,
                        });
                      }
                    })
                    .catch((error) => {
                      console.log("error", error)
                      this.setState({
                        isSpin: false,
                      });
                    });
                }}
              >
                <div className="card-body webkitcenter">
                  <div className="dcnachimage-holder">
                    <img
                      src={require("../assets/images/credit-card.png")}
                      alt=""
                    />
                  </div>
                  <div className="card-heading">
                    <h2>Debit Card eNach</h2>
                  </div>
                  {/* <div className="card-subheading">
                        <h2>Monitor your investments & business<br/>performance with dashboards</h2>
                      </div> */}
                  <div className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ChatSupportBtn history={this.props.history} />
      </>
    );
  }
}

export default withRouter(Nach_type);
