export const NormalizeData = (input) => {
  console.log("Input data:", input);
  let normalizedData = [];

  const getFileFormat = (filename) => {
    const dotIndex = filename.lastIndexOf(".");
    return dotIndex !== -1 ? filename.substring(dotIndex + 1) : "unknown";
  };

  const isValidFile = (filename) => {
    return getFileFormat(filename) !== "unknown" &&
      filename !== "upload_document_type_group_id" &&
      filename !== "upload_document_type_group_name";
  };

  try {
    // Case 1: Array of strings
    if (Array.isArray(input) && input.every((item) => typeof item === "string")) {
      normalizedData = input.map((path, index) => ({
        path,
        name: `file${index + 1}.${getFileFormat(path)}`,
      })).filter((file) => isValidFile(file.path));
    }
    // Case 2: Comma-separated string
    else if (typeof input === "string" && input.includes(",")) {
      normalizedData = input.split(",").map((path, index) => ({
        path: path.trim(),
        name: `file${index + 1}.${getFileFormat(path)}`,
      })).filter((file) => isValidFile(file.path));
    }
    // Case 3: Single path string with slashes
    else if (typeof input === 'string' && input.includes('/') && !input.includes(',')) {
      const path = input.trim();
      if (isValidFile(path)) {
        normalizedData = [{
          path,
          name: `file1.${getFileFormat(path)}`,
        }];
      }
    }
    // Case 4: Array of objects with `household_no` and `path` array of strings
    else if (Array.isArray(input) && input.every((item) => item.household_no && Array.isArray(item.path) && item.path.every((p) => typeof p === 'string'))) {
      normalizedData = input.flatMap((item) =>
        item.path.map((path, index) => ({
          path,
          name: `${item.household_no}_${index + 1}.${getFileFormat(path)}`,
        })).filter((file) => isValidFile(file.path))
      );
    }
    // Case 5: Array of objects with `household_no` and `path` array of objects (with full metadata)
    else if (Array.isArray(input) && input.every((item) => item.household_no && Array.isArray(item.path) && item.path.every((p) => p.filename && p.upload_document_type_group_id && p.upload_document_type_group_name))) {
      normalizedData = input.flatMap((item) =>
        item.path.map((file, index) => ({
          path: file.filename,
          name: `${item.household_no}_${index + 1}_${file.upload_document_type_group_name}.${getFileFormat(file.filename)}`,
        })).filter((file) => isValidFile(file.path))
      );
    }
    // Case 6: Array of objects with `household_no` and `path` array of objects (without full metadata)
    else if (Array.isArray(input) && input.every((item) => item.household_no && Array.isArray(item.path) && item.path.every((p) => p.filename && !p.upload_document_type_group_id && !p.upload_document_type_group_name))) {
      normalizedData = input.flatMap((item) =>
        item.path.map((file, index) => ({
          path: file.filename,
          name: `${item.household_no}_${index + 1}_unknown.${getFileFormat(file.filename)}`,
        })).filter((file) => isValidFile(file.path))
      );
    } else {
      throw new Error("Unsupported input format");
    }
  } catch (error) {
    console.error("Error normalizing data:", error);
  }

  console.log("Normalized data:", normalizedData);
  return normalizedData;
};
