import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { ThemeContext } from "../context/IsLoader";
import { logout, generateId } from "./Utility";
import '../assets/css/Payments.css';
import Razorpay from 'razorpay';

class Payments extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      isSpin: false,
      modalOpenMobile: false,
      modalOpenOtp: false,
      mobile: "",
      editMobile: false,
      otp: new Array(4).fill(""),
      hhnumber: "",
      result: {},
      hhId: "",
      amount: "",
      name: "",
      showAmtCard: false,
      errorMessage: "",
    };

    this.displayRazorpay = this.displayRazorpay.bind(this);
  }
  componentDidMount() {}

  handleHHnumber() {
    fetch(
      "https://asia-south1-omega-sorter-353009.cloudfunctions.net/dev_latest_household_payment",
      {
        method: "POST",
        headers: {
          //  "api-token": localStorage.getItem('api_token'),
          //   component_id: generate_Id1,
          //   apiId: generate_Id,
          //   api_group_id: "",
          //   component: this.constructor.name,
          //   user_id: localStorage.getItem("in_userid"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          hhnumber: this.state.hhnumber.toString(),
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log("json", data);
        if (
          data.error === "Household not found" ||
          this.state.hhnumber === ""
        ) {
          this.setState({
            showAmtCard: false,
          });
        }
        if (data.hhnumber) {
          this.setState({
            name: data.name,
            amount: data.amount_to_collect,
            hhnumber: data.hhnumber,
            hhnumberOnCard: data.hhnumber,
            showAmtCard: true,
            errorMessage: "",
          });
        } else {
          this.setState({
            errorMessage:
              data.error === "hhnumber is required"
                ? "Household Number is required"
                : data.error,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  handleChangePassword() {
    alert("a");
  }

  maskNumber(number) {
    // Mask the first 6 digits and keep the last 4 digits intact
    return "xxxxxx" + number.slice(-4);
  }

  moveToNextInput(currentInput, nextInputId) {
    if (currentInput.value.length === 1) {
      document.getElementById(nextInputId).focus();
    }
  }

  // Handle input change
  handleChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return;
    const newOtp = [...this.state.otp];
    newOtp[index] = value;
    this.setState({
      otp: newOtp,
    });

    // Move to the next input
    if (element.nextSibling && value) {
      element.nextSibling.focus();
    }
  };

  // Handle form submit
  handleSubmit = (e) => {
    e.preventDefault();
    alert(`Entered OTP: ${this.state.otp.join("")}`);
  };

  loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  displayRazorpay = async () => {
    const formdata1 = new FormData();
    formdata1.append("household_id", this.state.hhnumber);
    formdata1.append("amount", parseInt(this.state.amount));
    // Make an API call to your backend to create an order
    await customFetch(process.env.REACT_APP_URL + "/order/create", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // "Authorization": `Basic ${credentials}`,  // Add Basic Auth header
        // // Any other headers you might need
        // "Access-Control-Allow-Origin": '*',
      },
      body: formdata1,
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        this.setState({
          result: response[0],
        });
        console.log("cre", response[0], this.state.result);
        const { amount, id: order_id, currency } = response[0];
        var options = {
          "key": process.env.REACT_APP_rzp_key, // Enter the Key ID generated from the Dashboard
          "amount": amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          "currency": currency,
          "name": "Navadhan Capital Pvt Ltd.", //your business name
          "description": `Collection for ${this.state.hhnumber}`,
          "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKoCImzRGYjIXBOLGvldo_SKveUrqk1OiXhIg90e5XGQ&s",
          "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          "callback_url":
            "https://acen-receiver-service-dev.acen.app/callbacks/razorpay",
          "prefill": {
            //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
            name: this.state.name, //your customer's name
            email: "",
            contact: "", //Provide the customer's phone number for better conversion rates
          },
          "notes": {
            address: this.state.hhnumber,
          },
          "theme": {
            color: "#3399cc",
          },
          // Disable specific payment methods
          "method": {
            card: false, // Hide card option
            netbanking: false, // Hide netbanking option
            wallet: false, // Hide wallet option
            bank_transfer: false,
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      })
      .catch((error) => console.error("Network Error:", error));
  };

  render() {
    return (
      <>
        {this.state.isSpin ? (
          <div class="preloader">
            <div class="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}

        <div className="container">
          <div className="payload_view_navbar">
            <button
              className="button back_button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={require("../assets/images/back.png")} alt="" />
            </button>
            <h4>Payments</h4>
            <button className="button" onClick={() => logout()}>
              <img src={require("../assets/images/power-off.png")} alt="" />
            </button>
          </div>

          <section className={"container-payments"}>
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="household" className={"label"}>
                  Household Number
                </label>
              </div>
              <div className="col-md-8">
                <input
                  id="username"
                  type="text"
                  className={"form-control input"}
                  placeholder="Enter Household Number"
                  aria-label="Enter Household Number"
                  value={this.state.hhnumber}
                  onChange={(e) => {
                    this.setState({
                      hhnumber: e.target.value,
                    });
                  }}
                />
                <div className="text-danger font-weight-bold">
                  {this.state.errorMessage}
                </div>
              </div>
              <div className="col-md-4 text-center">
                <button
                  type="button"
                  className={"btn button font-weight-bold sbmt-btn"}
                  onClick={() => this.handleHHnumber()}
                >
                  Submit
                </button>
              </div>
            </div>
          </section>

          {this.state.showAmtCard && (
            <div
              className="card_block"
              // key={key}
              // onClick={() => this.showDetail()}
            >
              <div id="OvernightNameAmount" className="card_amount">
                <div>
                  <p className="card_amount_name font-weight-bold">
                    {this.state.name}
                  </p>
                  <p className="card_amount_name">
                    {this.state.hhnumberOnCard}
                  </p>
                </div>
                <span className="font-weight-bold amtinCard">
                  Rs. {this.state.amount}
                </span>
              </div>
              <div id="OvernightDate" className="card_footer">
                <ul className="card_footer_left">
                  {/* <li>Date : {'25 Jul 2024'}</li>
                        <li>Time : {'11 pm'}</li> */}
                </ul>
                <span className="">
                  <button
                    id="rzp-button1"
                    className="btn font-weight-bold"
                    onClick={(e) => {
                      // e.stopPropagation();
                      this.displayRazorpay();
                      // this.setState({
                      //   modalOpenOtp: true,
                      // });
                    }}
                  >
                    Pay
                  </button>
                </span>
              </div>
            </div>
          )}

          <div
            className={
              this.state.modalOpenMobile
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content cdb-confirm-box col-sm-12">
                <button
                  type="button"
                  className="close text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.setState({
                      modalOpenMobile: false,
                    });
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {this.state.editMobile ? (
                  <>
                    <input
                      className="form-control input"
                      maxLength={10}
                      minLength={10}
                      type="text"
                      value={this.state.mobile}
                      onChange={(e) => {
                        this.setState({
                          mobile: e.target.value,
                        });
                      }}
                    />{" "}
                    <span className="card_btn">
                      <button
                        className="btn"
                        disabled={
                          this.state.mobile.length === 10 ? false : true
                        }
                        onClick={() => {
                          if (!this.state.editMobile) {
                            this.setState({
                              editMobile: true,
                            });
                          } else {
                            this.setState({
                              editMobile: false,
                            });
                          }
                        }}
                      >
                        BACK
                      </button>
                    </span>
                  </>
                ) : (
                  <div className="d-flex maskNum">
                    <span className="m-3 mobile">
                      Mobile: {this.maskNumber(this.state.mobile)}
                    </span>{" "}
                    <span className="card_btn">
                      <button
                        className="btn"
                        disabled={
                          this.state.mobile.length === 10 ? false : true
                        }
                        onClick={() => {
                          if (!this.state.editMobile) {
                            this.setState({
                              editMobile: true,
                            });
                          } else {
                            this.setState({
                              editMobile: false,
                            });
                          }
                        }}
                      >
                        EDIT
                      </button>
                    </span>
                  </div>
                )}
                {!this.state.editMobile && (
                  <span className="card_btn">
                    <button
                      className="btn"
                      onClick={() => {
                        this.setState({
                          modalOpenMobile: false,
                          modalOpenOtp: true,
                        });
                      }}
                    >
                      SAVE
                    </button>
                  </span>
                )}
              </div>
            </div>
          </div>

          <div
            className={
              this.state.modalOpenOtp
                ? "modal fade bd-example-modal-sm show mymodal"
                : "modal fade bd-example-modal-sm"
            }
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="confirm-box"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content payments-confirm-box col-sm-12">
                <div className="row">
                  <button
                    type="button"
                    className="close card-head col-md-12"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      this.setState({
                        modalOpenOtp: false,
                      });
                    }}
                  >
                    <span className="text-center col-md-12 text-white">
                      Enter OTP
                    </span>
                    <span aria-hidden="true modal-header text-right">
                      &times;
                    </span>
                  </button>
                </div>
                {/* <p className="text-center">Enter OTP</p>
                         <div className="text-center">
                          <span><input id="input1" className="otp_input input" type="text" maxLength={1} oninput="this.moveToNextInput(this, 'input2')" /></span>
                          <span><input id="input2" className="otp_input input" type="text" maxLength={1} oninput="this.moveToNextInput(this, 'input3')" /></span>
                          <span><input id="input3" className="otp_input input" type="text" maxLength={1} oninput="this.moveToNextInput(this, 'input4')" /></span>
                          <span><input id="input4" className="otp_input input" type="text" maxLength={1} /></span>
                          <span className="card_btn"><button className="btn">Submit</button></span>
                         </div> */}

                <div className="d-flex justify-content-center align-items-center">
                  <div className="text-center" style={{ padding: "20px" }}>
                    <div className="">
                      <h5 className="text-white">Enter OTP</h5>
                    </div>
                    <div className="card-body">
                      <form onSubmit={this.handleSubmit}>
                        <div className="d-flex justify-content-between mb-4">
                          {this.state.otp.map((data, index) => (
                            <input
                              key={index}
                              type="text"
                              className="form-control otp-input"
                              maxLength="1"
                              value={data}
                              onChange={(e) =>
                                this.handleChange(e.target, index)
                              }
                              onFocus={(e) => e.target.select()} // Automatically selects input content
                            />
                          ))}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-block text-white"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Payments);
