// AT-2102 | AT-2161 | Ankur Makavana | 18-07-2023 | Reports to have to 2 sub-sections: 1. Mobile Reports Desktop 2. MIS Reports Reports Master - to be greyed out for now Finance Reports - to be greyed out for now Business Reports
import React from "react";
import customFetch from "./apicall/api";
import { logout, generateId } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import Mobile_report from "./Mobile_report";
import PBI_report from "./PBI_report";
import { PEDiv } from "./permissionComponents";
import SupersetDashboard from "./SupersetDashboard";

const generate_Id1 = generateId(10);
class Report_categories extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      sub_categories_data: [],
      isSpin: false,
      flag: 0,
    };
  }
  componentDidMount() {
    if (
      window.Android &&
      typeof window.Android.getDataFromLocalStorage === "function"
    ) {
      const data = window.Android.getDataFromLocalStorage("your_key");
      if (data) {
        this.setState({ isSpin: false, flag: 1 }); // Set flag to 1 if accessed from Android
        const tokenParts = data.split(",");
        const tokenData = {};

        tokenParts.forEach((part) => {
          const [key, value] = part.split(":");
          tokenData[key] = value;
        });

        console.log("AndroidInterface->", data);
      }
    } else {
      console.log(
        "Error in AndroidInterface: Android method not found or not available."
      );
    }


    setTimeout(() => {
      if (document.readyState === "complete") {
        this.context.onPageLoad({
          component: "ReportCategories",          
          component_id: generate_Id1,          
        });
      } else {
        window.addEventListener("load", this.context.onPageLoad());
        this.cleanup = () =>
          window.removeEventListener("load", this.context.onPageLoad());
      }
    }, 1000);
    const { api_token, categories_id, sub_categories } =
      (this.props.location && this.props.location.state) || {};

    this.state.sort_data = [];

    for (let i = 0; i < sub_categories.length; i++) {
      // this.state.pgdata.push(this.state.partnerpayment[i]);
      if (
        sub_categories[i].report_category_id == categories_id //parseInt(e.target.value)
      ) {
        this.state.sort_data.push(sub_categories[i]);
        this.setState({
          sub_categories_data: this.state.sort_data,
        });
      }
    }
  }
  render() {
    const {
      api_token,
      categories_name,
      categories_id,
      effective_designation_id,
    } = (this.props.location && this.props.location.state) || {};
    const { sub_categories_data } = this.state;
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        <div className="container no-container" id="reportCategories">
        {/* <div className="container no-container"> */}
          <div className="top_nav_report">
            {sub_categories_data.length > 0 ? (
              <a>
                <div
                  className="icon_bg-report"
                  onClick={() => this.props.history.goBack()}
                >
                  {/* AT-2335 ||Rohit Chitampalle ||23-08-2023 ||change in icon */}
                  <img
                    src={require("../assets/images/back-white.png")}
                    className="icon_img"
                  />
                </div>
              </a>
            ) : (
              <a>
                {/*AT-2335 ||Rohit Chitampalle ||23-08-2023 ||added icon and redirect to dashboard*/}
                <div
                  className="icon_bg-report"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/dashboard",
                      state: {
                        api_token: api_token,
                      },
                    });
                  }}
                >
                  {/* AT-2335 ||Rohit Chitampalle ||23-08-2023 ||change in icon */}
                  <img
                    src={require("../assets/images/home_white_logo.png")}
                    className="icon_img"
                  />
                </div>
              </a>
            )}
            <span className="head-title">{categories_name}</span>

            <a>
            {this.state.flag === 0 && (
              <div className="log-out-reporthead">
                <img
                  src={require("../assets/images/power-back.png")}
                  onClick={() => logout()}
                  alt=""
                />
              </div>
            )}
            </a>
          </div>
          <div className="dashboard-card-holder row mt-4">
            {categories_id == 3 ? (
              <PBI_report
                api_token={api_token}
                categories_id={categories_id}
                effective_designation_id={effective_designation_id}
                props={this.props}
              />
            ) : sub_categories_data.length > 0 ? (
              sub_categories_data.map((categories, index) => (
                <>
                  {categories.report_subcategory_id == 1 ? (
                    // <PEDiv element_id={categories.permission_id}
                    //   key={index}
                    //   className={
                    //     categories.status != "A" ||
                    //     process.env.REACT_APP_Corporate_Rep.split(",").includes(
                    //       effective_designation_id.toString()
                    //     ) == false
                    //       ? "card mb-3 card-border-small not-allowed"
                    //       : "card mb-3 card-border-small"
                    //   }
                    //   onClick={() => {
                    //     this.props.history.push({
                    //       pathname: "/Report_list",
                    //       state: {
                    //         api_token: api_token,
                    //         subcategories_id: categories.report_subcategory_id,
                    //         subcategories_name: categories.subcategory_name,
                    //       },
                    //     });
                    //   }}
                    // >
                    //   <div class="report-card-header text-center">
                    //     <h2>{categories.subcategory_name}</h2>
                    //   </div>
                    //   <div class="card-body report-card-bottom">
                    //     <div className="text-center">
                    //       {categories.ui_img_path ? (
                    //         <img
                    //           src={require("../assets/images/" +
                    //             categories.ui_img_path)}
                    //           alt=""
                    //         />
                    //       ) : (
                    //         <img
                    //           src={require("../assets/images/check-list.png")}
                    //           alt=""
                    //         />
                    //       )}
                    //     </div>
                    //   </div>
                    // </div>
                    <div className="image-report-2">
                      <div
                        className={
                          categories.status != "A" ||
                          process.env.REACT_APP_CORPORATE_REP.split(
                            ","
                          ).includes(effective_designation_id.toString()) ==
                            false
                            ? "not-allowed m-2"
                            : "m-2"
                        }
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/Report_list",
                            state: {
                              api_token: api_token,
                              subcategories_id: categories.report_subcategory_id,
                              subcategories_name: categories.subcategory_name,
                            },
                          });
                        }}
                      >
                        {categories.ui_img_path ? (
                          <img
                            src={require("../assets/images/" +
                              categories.ui_img_path)}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../assets/images/check-list.png")}
                            alt=""
                          />
                        )}
                        <h5>{categories.subcategory_name}</h5>
                      </div>
                    </div>
                  ) : categories.report_subcategory_id == 2 ? (                    
                    <div className="image-report-2">
                      <PEDiv element_id={categories.permission_id}
                        className={
                          categories.status != "A" ||
                        process.env.REACT_APP_FINANCE_REP.split(",").includes(
                          effective_designation_id.toString()
                        ) == false ||
                        process.env.REACT_APP_FINANCE_REP_BRANCH.split(
                          ","
                        ).includes(
                          localStorage.getItem("branch_id").toString()
                        ) == false
                            ? "not-allowed m-2"
                            : "m-2"
                        }
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/Report_list",
                            state: {
                              api_token: api_token,
                              subcategories_id: categories.report_subcategory_id,
                              subcategories_name: categories.subcategory_name,
                            },
                          });
                        }}
                      >
                        {categories.ui_img_path ? (
                          <img
                            src={require("../assets/images/" +
                              categories.ui_img_path)}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../assets/images/check-list.png")}
                            alt=""
                          />
                        )}
                        <h5>{categories.subcategory_name}</h5>
                      </PEDiv>
                    </div>
                  ) : (
                    // <PEDiv element_id={categories.permission_id}
                    //   key={index}
                    //   className={
                    //     categories.status != "A"
                    //       ? "card mb-3 card-border-small not-allowed"
                    //       : "card mb-3 card-border-small"
                    //   }
                    //   onClick={() => {
                    //     this.props.history.push({
                    //       pathname: "/Report_list",
                    //       state: {
                    //         api_token: api_token,
                    //         subcategories_id: categories.report_subcategory_id,
                    //         subcategories_name: categories.subcategory_name,
                    //       },
                    //     });
                    //   }}
                    // >
                    //   <div class="report-card-header text-center">
                    //     <h2>{categories.subcategory_name}</h2>
                    //   </div>
                    //   <div class="card-body report-card-bottom">
                    //     <div className="text-center">
                    //       {categories.ui_img_path ? (
                    //         <img
                    //           src={require("../assets/images/" +
                    //             categories.ui_img_path)}
                    //           alt=""
                    //         />
                    //       ) : (
                    //         <img
                    //           src={require("../assets/images/check-list.png")}
                    //           alt=""
                    //         />
                    //       )}
                    //     </div>
                    //   </div>
                    // </div>
                    <div className="image-report-2">
                      <div
                        className={
                          categories.status != "A"
                            ? "not-allowed m-2"
                            : "m-2"
                        }
                        onClick={() => {
                          this.props.history.push({
                            pathname: "/Report_list",
                            state: {
                              api_token: api_token,
                              subcategories_id: categories.report_subcategory_id,
                              subcategories_name: categories.subcategory_name,
                            },
                          });
                        }}
                      >
                        {categories.ui_img_path ? (
                          <img
                            src={require("../assets/images/" +
                              categories.ui_img_path)}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require("../assets/images/check-list.png")}
                            alt=""
                          />
                        )}
                        <h5>{categories.subcategory_name}</h5>
                      </div>
                    </div>
                  )}
                </>
              ))
            ) : categories_id == 4 ? (
              <SupersetDashboard
                api_token={api_token}
                categories_id={categories_id}
                effective_designation_id={effective_designation_id}
                props={this.props}
              />
            ) : (
              // <div className="text-center">No Data Found</div>
              <Mobile_report
                api_token={api_token}
                categories_id={categories_id}
                effective_designation_id={effective_designation_id}
                props={this.props}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
export default Report_categories;
