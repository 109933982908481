import React, { Component } from "react";
import { withRouter } from "react-router";
import CustomDownload from "../hooks/CustomDownload";

const FilesDownload = ({ initialData }) => {
  const data1 = initialData
    ? JSON.parse(initialData)
    : 
    //[
      //   {
      //     household_no: "MP001LE000269",
      //     path: [
      //       'deposit_slips_by_transaction_officer/dev/2024/06/232_14_2079_1718202225435.png',
      //       'deposit_slips_by_transaction_officer/dev/2024/06/232_14_2079_1718202225435.png'
      //     ]
      //   }
      // ];
      [
        {
          household_no: "MP001LE000269",
          path: [
            "deposit_slips_by_transaction_officer/dev/2024/06/232_14_2079_1718202225435.png",
            "upload_document_type_group_id",
            "upload_document_type_group_name",
            "appraisal/2023/6/800_bf39050f2c-2023-06-28-15-21-26-100.jpeg",
            "upload_document_type_group_id",
            "appraisal/2023/6/249_0977c8cede-2023-06-28-15-23-36-100.jpeg",
            "upload_document_type_group_id",
            "appraisal/2023/6/799_61239d7396-2023-06-28-15-29-15-100.jpeg",
            "appraisal/2023/6/883_7b12ce066d-2023-06-28-15-42-48-100.jpg",
          ],
        },
      ];
      // [
      //   {
      //     household_no: "MP001LE000269",
      //     path: [
      //       {
      //         filename: "KYC/2021/03/MP001LE001392-B_Voter ID front.jpg",
      //         upload_document_type_group_id: 1,
      //         upload_document_type_group_name: "KYC",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/800_bf39050f2c-2023-06-28-15-21-26-100.jpeg",
      //         upload_document_type_group_id: 5,
      //         upload_document_type_group_name: "Business Photos",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/249_0977c8cede-2023-06-28-15-23-36-100.jpeg",
      //         upload_document_type_group_id: 6,
      //         upload_document_type_group_name: "House Photos",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/799_61239d7396-2023-06-28-15-29-15-100.jpeg",
      //         upload_document_type_group_id: 10,
      //         upload_document_type_group_name: "Family Tree",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/883_7b12ce066d-2023-06-28-15-42-48-100.jpg",
      //         upload_document_type_group_id: 10,
      //         upload_document_type_group_name: "Family Tree",
      //       },
      //       {
      //         filename: "KYC/2021/03/MP001LE001392-B_Voter ID front.jpg",
      //         upload_document_type_group_id: 1,
      //         upload_document_type_group_name: "KYC",
      //       },
      //       {
      //         filename: "KYC/2021/03/MP001LE001392-B_Voter ID front.jpg",
      //         upload_document_type_group_id: 1,
      //         upload_document_type_group_name: "KYC",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/684_08830ffff2-2023-06-29-12-48-52-100.jpeg",
      //         upload_document_type_group_id: 4,
      //         upload_document_type_group_name: "Asset Holding",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/316_cac3e192a2-2023-06-29-12-49-16-100.jpeg",
      //         upload_document_type_group_id: 4,
      //         upload_document_type_group_name: "Asset Holding",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/558_e2ad5ec71b-2023-06-29-12-49-39-100.jpeg",
      //         upload_document_type_group_id: 7,
      //         upload_document_type_group_name: "Bank",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/973_15944ef4ab-2023-06-29-12-50-05-100.jpeg",
      //         upload_document_type_group_id: 10,
      //         upload_document_type_group_name: "Family Tree",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/469_7c8e64a555-2023-06-29-12-50-29-100.jpeg",
      //         upload_document_type_group_id: 10,
      //         upload_document_type_group_name: "Family Tree",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/918_8eff4cd4d1-2023-06-29-12-54-07-100.jpeg",
      //         upload_document_type_group_id: 10,
      //         upload_document_type_group_name: "Family Tree",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/248_a68fa5c10d-2023-06-29-12-54-34-100.jpg",
      //         upload_document_type_group_id: 10,
      //         upload_document_type_group_name: "Family Tree",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/752_d2745b77c4-2023-06-29-13-02-53-100.jpg",
      //         upload_document_type_group_id: 13,
      //         upload_document_type_group_name: "Living Standard",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/391_79bb5d9367-2023-06-29-13-03-42-100.jpg",
      //         upload_document_type_group_id: 13,
      //         upload_document_type_group_name: "Living Standard",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/6/990_31e575ce1e-2023-06-29-13-04-24-100.jpg",
      //         upload_document_type_group_id: 13,
      //         upload_document_type_group_name: "Living Standard",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/7/841_6454a66ae8-2023-07-24-18-46-48-100.jpeg",
      //         upload_document_type_group_id: 3,
      //         upload_document_type_group_name: "Income Documents",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/8/683_538741ba1c-2023-08-21-06-16-50-100.jpeg",
      //         upload_document_type_group_id: 3,
      //         upload_document_type_group_name: "Income Documents",
      //       },
      //       {
      //         filename:
      //           "appraisal/2023/8/939_953ee9d14e-2023-08-21-07-50-19-100.jpeg",
      //         upload_document_type_group_id: 7,
      //         upload_document_type_group_name: "Bank",
      //       },
      //       {
      //         filename:
      //           "credit_bureau/2021/01/MP001LE000269A-CBReport-MANOJGEER.html",
      //         upload_document_type_group_id: 2,
      //         upload_document_type_group_name: "Credit Bureau Report",
      //       },
      //       {
      //         filename:
      //           "credit_bureau/2021/01/MP001LE000269B-CBReport-SHYAMUBAI.html",
      //         upload_document_type_group_id: 2,
      //         upload_document_type_group_name: "Credit Bureau Report",
      //       },
      //       {
      //         filename:
      //           "credit_bureau/2021/01/MP001LE000269C-CBReport-RAMESHGIR.html",
      //         upload_document_type_group_id: 2,
      //         upload_document_type_group_name: "Credit Bureau Report",
      //       },
      //       {
      //         filename:
      //           "credit_bureau/2021/01/MP001LE000269D-CBReport-SEEMABAI.html",
      //         upload_document_type_group_id: 2,
      //         upload_document_type_group_name: "Credit Bureau Report",
      //       },
      //     ],
      //   },
      // ];

  const data2 =
    "deposit_slips_by_transaction_officer/dev/2024/06/232_14_2079_1718202225435.png,deposit_slips_by_transaction_officer/dev/2024/06/232_14_2079_1718202225435.png";

  const data3 =
    "deposit_slips_by_transaction_officer/dev/2024/06/232_14_2079_1718202225435.png";

  return <CustomDownload data={data1} />;
};

export default withRouter(FilesDownload);
