// AT-2102 | AT-2161 | Ankur Makavana | 18-07-2023 | Reports to have to 2 sub-sections: 1. Mobile Reports Desktop 2. MIS Reports Reports Master - to be greyed out for now Finance Reports - to be greyed out for now Business Reports
import React from "react";
import { logout, consolidate, filterData } from "./Utility";
import { ThemeContext } from "../context/IsLoader";
import moment from "moment";
import { async } from "q";
import load_img from "../assets/images/report_load.gif";
import customFetch from "./apicall/api";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { DatePicker, Select } from "antd";
import { PESelect } from "./permissionComponents";
const { RangePicker } = DatePicker;
//import "antd/dist/antd.css";

class Mobile_report extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      reportList_data: [],
      report_config: [],
      report_linkdata: [],
      isVisible: true,
      isInitial: true,
      loading: false,
      isloaded: 0,
      pin: false,
      drop: false,
      period_val: [],
    };
    this.iframeRef = React.createRef();
    this.pushData = this.pushData.bind(this);
  }

  // Ankur Makavana | 18-07-2023 | get report list based on sub categories
  componentDidMount() {
    const { api_token, categories_id } = this.props || {};
    this.setState({ isSpin: true });
    const params = {
      report_category_id: categories_id,
      em_branch_id: localStorage.getItem("em_branch_id"),
      employee_id: localStorage.getItem("employee_id"),
    };
    const queryParams = new URLSearchParams(params).toString();
    customFetch(
      process.env.REACT_APP_URL + "/get/mobile/report?" + queryParams,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        this.setState({
          reportList_data: data,
          isSpin: false,
        });
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  }
  componentDidUpdate(props) {
    console.log("props");
  }

  pushData(e) {
    // {
    //     [item.inputkey]: this.state[
    //       item.inputkey
    //     ],
    //   }

    console.log("pushData", e.target.name + ":" + e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (this.state.period_val.length == 0) {
      this.state.period_val.push({
        [e.target.name]: e.target.value,
      });
    } else {
      var exists =
        this.state.period_val.filter(function(o) {
          return o.hasOwnProperty(e.target.name);
        }).length > 0;

      console.log(exists);

      if (!exists) {
        this.state.period_val.push({
          [e.target.name]: e.target.value,
        });
      } else {
        this.state.period_val.map((item, index) => {
          if (Object.keys(item)[0] == e.target.name) {
            this.state.period_val.splice(index, 1);
            this.state.period_val.push({
              [e.target.name]: e.target.value,
            });
          }
        });
      }
    }
    if (
      this.state.period_val.length ==
      this.state.report_config[0].report_config.length
    ) {
      console.log("period_val", this.state.period_val.length);
      console.log(
        "report_config_length",
        this.state.report_config[0].report_config.length
      );
      this.setState({ loadButtonFlag: 1 });
    }
    // this.state.period_val.push({
    //   [item.inputkey]: this.state[item.inputkey]
    // });
    console.log(this.state.period_val);
    //
  }

  get_reportconfig(e) {
    console.log(e, "select period");
    const { api_token } =
      (this.props.location && this.props.location.state) || {};
    let splitreport = e.split("~");
    this.setState({
      isSpin: true,
      report_list: splitreport[0],
      report_name: splitreport[1],
    });
    customFetch(process.env.REACT_APP_URL + "/get/parameters?report_id=" + e, {
      method: "GET",
      headers: { "api-token": api_token },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }

        if (data.length > 0) {
          data[0].report_config = JSON.parse(data[0].report_config);
          // console.log(JSON.parse(data[0].report_config));
          // console.log(JSON.parse(data[0].report_config).length);
          // if (JSON.parse(data[0].report_config).length > 0) {

          // }
          const hasAPIInputSource = data.some((item) => {
            const reportConfig = item.report_config;
            console.log("apidata->", reportConfig);
            return reportConfig.some(
              (configItem) => configItem.inputsource === "API"
            );
          });
          if (hasAPIInputSource) {
            this.processReportConfig(data[0]);

            this.setState({
              isSpin: false,
              loadButtonFlag: 0,
              period_val: [],
            });
          } else {
            this.setState({
              report_config: data,
              isSpin: false,
              loadButtonFlag: 0,
              period_val: [],
            });
            console.log(data);
          }
          // this.setState({
          //   report_config: data,
          //   isSpin: false,
          // });
        } else {
          this.setState({
            period_val: [],
            isSpin: false,
            report_config: [],
            loadButtonFlag: 1,
          });
        }
      })
      .catch((error) => {
        console.log("error", error)
        this.setState({
          isSpin: false,
        });
      });
  }

  processReportConfig = async (data) => {
    const { report_config } = data;
    const hasAPIInputSource = report_config.some(
      (item) => item.inputsource === "API"
    );
    report_config.forEach((dataitem) => {
      this.setState({
        [dataitem.inputkey]: "",
      });
    });
    if (hasAPIInputSource) {
      const updatedConfig = [];
      const processedItems = await Promise.all(
        report_config.map(async (item) => {
          const processedItem = await this.fetchDataForInputKey(
            item,
            data.report_id
          );
          return processedItem;
        })
      );

      // Once all items are processed, update state
      this.setState({
        report_config: [{ ...data, report_config: processedItems }],
        isSpin: false,
      });
    } else {
      this.setState({
        report_config: data,
        isSpin: false,
        loadButtonFlag: 1,
      });
    }
  };
  async fetchDataForInputKey(item, report_id) {
    console.log("call fetchDataForInputKey", item);
    const { api_token } = this.props || {};
    // Simulate fetching data from API
    try {
      let _body = {
        keyname: item.inputkey,
        params: [],
      };
      const response = await customFetch(
        process.env.REACT_APP_URL + "/reports/" + report_id,
        {
          method: "POST",
          body: JSON.stringify(_body),
          headers: {
            "api-token": api_token,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      console.log("data--", data.data);
      const updatedItem = { ...item, inputdata: data.data };
      return updatedItem;
      //return data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }

  getIndianTimeISOString() {
    const now = new Date();
  
    const utcOffset = 5.5 * 60 * 60 * 1000;
    const istDate = new Date(now.getTime() + utcOffset);
  
    const year = istDate.getUTCFullYear();
    const month = String(istDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(istDate.getUTCDate()).padStart(2, '0');
    const hours = String(istDate.getUTCHours()).padStart(2, '0');
    const minutes = String(istDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(istDate.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(istDate.getUTCMilliseconds()).padStart(3, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  loadreport(e) {
    e && e.preventDefault();
    const { api_token, effective_designation_id } = this.props || {};
    this.setState({ pin: true });
    const empid = localStorage.getItem("employee_id");
    let parameter_ary = [];
    let flag = 0;
    // console.log(this.state.period_val);
    this.state.report_config.map((configdata) =>
      configdata.report_config.length > 1
        ? this.state.period_val.length !== configdata.report_config.length
          ? (flag = 1)
          : (parameter_ary = this.state.period_val)
        : // configdata.report_config.map((item) => {
          //   parameter_ary.push({
          //     [item.inputkey]: this.state[
          //       item.inputkey
          //     ],
          //   })

          // })
          parameter_ary.push({
            [configdata.report_config[0].inputkey]: this.state[
              configdata.report_config[0].inputkey
            ],
          })
    );

    console.log("parameter_ary", parameter_ary);
    if (flag) {
      alert("PLease Select All Optons!");
      this.setState({ pin: false });
      return false;
    }

    const data = new FormData()
    const user_id = localStorage.getItem("in_userid")
    data.append("report_id", this.state.report_list)
    data.append("report_name", this.state.report_name)
    data.append("report_parameters", JSON.stringify(parameter_ary))
    data.append("user_id", user_id)
    data.append("start_time:", this.getIndianTimeISOString())

    const formdata = new FormData();
    formdata.append("report_id", this.state.report_list);
    formdata.append("employee_id", empid);
    formdata.append("parameter", JSON.stringify(parameter_ary));
    // console.log(this.state.report_list);
    // console.log(empid);
    // console.log(JSON.stringify(parameter_ary));
    // return false;
    customFetch(process.env.REACT_APP_URL + "/set/procedure", {
      method: "POST",
      headers: { "api-token": api_token },
      body: formdata,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
        //   localStorage.clear();
        //   this.props.history.push("/");
        // }
        // this.setState({
        //   isSpin: false,
        // });
        console.log(data);
        if (data[0].status == 1) {
          this.get_reportlink();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        data.append("end_time", this.getIndianTimeISOString())
        const response = customFetch(
          process.env.REACT_APP_URL + "/reports/log",
          {
            method: "POST",
            body: data,
            headers: {
              "api-token": api_token,
            },
          }
        )
      })
  }

  get_reportlink = () => {
    console.log("get_reportlink");
    const { api_token, effective_designation_id } = this.props || {};
    console.log(effective_designation_id);
    this.setState({ pin: true });
    customFetch(
      process.env.REACT_APP_URL +
        "/get/embed/url?report_id=" +
        this.state.report_list +
        "&effective_designation_id=" +
        effective_designation_id,
      {
        method: "GET",
        headers: { "api-token": api_token },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.api_code === 4 || data.api_code === 3 || data.api_code === 5) {
          localStorage.clear();
          this.props.history.push("/");
        }
        console.log(data);
        this.setState(
          {
            report_linkdata: data,
            // isSpin: false,
            report_url: "",
          },
          () => {
            this.makeRequest();
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  makeRequest = async () => {
    //this.setState({ report_url: "" });
    console.log(this.state.report_linkdata);
    var username = this.state.report_linkdata[0].report_user; // Replace with your actual username
    var password = this.state.report_linkdata[0].report_password; // Replace with your actual password
    var url =
      this.state.report_linkdata[0].report_link +
      "userid=" +
      username +
      "&password=" +
      password +
      "&" +
      this.state.report_linkdata[0].link_parameter +
      localStorage.getItem("employee_id"); // Replace with your API endpoint URL
    console.log("URL=>", url);
    this.setState({ report_url: url });
    // try {
    //   const response = await customFetch(url, {
    //     method: "GET",
    //     headers: {
    //       Authorization: "Basic " + btoa(username + ":" + password),
    //       // Other headers if needed
    //     },
    //   });

    //   if (response.ok) {
    //     const reportUrl = await response.text();
    //     console.log(reportUrl);
    //     //setIframeUrl(reportUrl);
    //     this.setState({
    //       report_url: `data:text/html;base64,${btoa(reportUrl)}`,
    //     });
    //   } else {
    //     console.error("Failed to fetch report URL");
    //   }
    // } catch (error) {
    //   console.error("Error fetching report URL:", error);
    // }

    // const formData = new FormData();
    // formData.append("j_username", username);
    // formData.append("j_password", password);

    // customFetch(url, {
    //   method: "POST",
    //   body: formData,
    // })
    //   .then((response) => {
    //     if (response.ok) {
    //       console.log("Login successful!", response);

    //       const reportEndpoint = url;

    //       customFetch(reportEndpoint, {
    //         method: "GET",
    //         credentials: "include", // Include cookies in the request
    //       })
    //         .then((response) => response.text())
    //         .then((reportContent) => {
    //           // Process the report content and display it in your React component
    //           //console.log(reportContent);
    //           this.setState({
    //             report_url: `data:text/html;base64,${btoa(reportContent)}`,
    //           });
    //         })
    //         .catch((error) => {
    //           console.error(
    //             "An error occurred while fetching the report:",
    //             error
    //           );
    //         });
    //       // You can now proceed with authenticated requests
    //     } else {
    //       console.error("Login failed.");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("An error occurred:", error);
    //   });
  };
  out = () => {
    this.setState({
      drop: !this.state.drop,
    });
  };
  updateurl = () => {
    console.log("update url call");
  };
  updateslide = () => {
    // e.preventdefault();
    this.setState({
      isVisible: !this.state.isVisible,
      drop: false,
    });
    if (this.state.isVisible == false) {
      this.setState({
        isVisible: false,
      });
    }
  };
  hideSpinner = () => {
    const { props } = this.props || {};
    this.setState({
      loading: true,
      pin: false,
    });
    //props.props.history.goBack();
  };
  render() {
    const { api_token, subcategories_name } =
      (this.props.location && this.props.location.state) || {};
    const { reportList_data } = this.state;
    console.log("report=>",this.state.period_val);
    return (
      <>
        {this.state.isSpin ? (
          <div className="preloader">
            <div className="loader" ng-hide="data.length > 0"></div>
          </div>
        ) : (
          ""
        )}
        {this.state.pin ? (
          <div className="preloader">
            <img src={load_img} />
          </div>
        ) : (
          ""
        )}
        {/* <div
          className={
            isErrOpen
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.setState({ isErrOpen: false })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p>{api_error_message}</p>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className=" mb-2 inputholder mt-2">
            {/* {this.state.isVisible && ( */}
            <div id="accordion">
              <div class="">
                <div
                  id="headingOne"
                  className={
                    !this.state.isVisible
                      ? "report_head show_title "
                      : "report_head "
                  }
                  onClick={() => {
                    this.out();
                  }}
                >
                  <h5
                    class="mb-0"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    // aria-expanded="true"
                    // aria-controls="collapseOne"
                    id="colapse"
                  >
                    {!this.state.isInitial &&
                      !this.state.isVisible &&
                      this.state.report_name}
                  </h5>
                  {!this.state.isInitial &&
                    !this.state.isVisible &&
                    (!this.state.drop ? <DownOutlined /> : <UpOutlined />)}
                </div>

                <div
                  id="collapseOne"
                  class={
                    !this.state.isVisible && !this.state.drop
                      ? "collapse"
                      : "collapse show"
                  }
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="">
                    <div className="row">
                      <div className="report_inputs pr-1 mb-1">                        
                        {/* <select
                          className="form-control text-center"
                          name="report_list_data"
                          value={this.state.report_list_data}
                          onChange={(e) => {
                            this.setState({ report_list_data: e.target.value });
                            this.get_reportconfig(e.target.value);
                          }}
                        >
                          <option value="" disabled selected>
                            Select Report
                          </option>
                          {reportList_data &&
                            reportList_data.map((reportList, index) => (
                              <option
                                value={
                                  reportList.report_id +
                                  "~" +
                                  reportList.report_name
                                }
                                key={index}
                              >
                                {reportList.report_name}
                              </option>
                            ))}
                        </select> */}
                        <PESelect element_id={"MobileReportSelect"}
                          showSearch
                          className="form-control text-center"
                          onChange={(e) => {
                            this.setState({ report_list_data: e });
                            this.get_reportconfig(e);
                          }}
                          placeholder="Select Report"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option && option.label
                              ? option.label.toLowerCase()
                              : ""
                            ).includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA && optionA.label
                              ? optionA.label.toLowerCase()
                              : ""
                            ).localeCompare(
                              optionB && optionB.label
                                ? optionB.label.toLowerCase()
                                : ""
                            )
                          }
                          options={
                            reportList_data &&
                            reportList_data.map((reportList) => {
                              return {
                                label: reportList.report_name,
                                value:
                                  reportList.report_id +
                                  "~" +
                                  reportList.report_name,
                              };
                            })
                          }
                        />
                      </div>

                      {console.log(
                        "this.state.report_config",
                        this.state.report_config
                      )}
                      {this.state.report_config.map((configdata) =>
                        configdata.report_config.length > 1 ? (
                          configdata.report_config.map((item) => (
                            <>
                              {//this.state.report_config &&
                              // JSON.parse(configdata.report_config).inputtype ==
                              item.inputtype == "Dropdown" ? (
                                <div className="report_inputs pl-1 mb-1">                                  
                                  <select
                                    className="form-control text-center"
                                    name={
                                      item.inputkey
                                      // JSON.parse(configdata.report_config).inputkey
                                    }
                                    value={
                                      this.state[
                                        item.inputkey
                                        // JSON.parse(configdata.report_config).inputkey
                                      ]
                                    }
                                    onChange={
                                      this.pushData
                                      // temp = this.state.period_val.push(e.target.value)
                                      // this.setState({
                                      //   [item.inputkey]: e.target.value,
                                      //   period_val: ,
                                      // })
                                    }
                                  >
                                    <option value="" disabled selected>
                                      {item.inputlabel}
                                    </option>
                                    {item.inputdata.map((configdata1) => (
                                      <option value={configdata1.datakey}>
                                        {configdata1.datakey}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              ) : item.inputtype == "calendar_yearmonth" ? (
                                <div className="report_inputs pl-1 mb-1">                                  
                                  <DatePicker
                                    format={"MMM-YY"}
                                    className="antd_dateform-control"
                                    picker="month"
                                    placeholder="Select Month"
                                    onChange={(e) =>
                                      this.setState((prevState) => ({
                                        [configdata.report_config[0]
                                          .inputkey]: `${
                                          moment(e)
                                            .format("YYYY-MM")
                                            .split("-")[0]
                                        }-${
                                          moment(e)
                                            .format("YYYY-MM")
                                            .split("-")[1]
                                        }-01`,
                                        period_val: [
                                          ...prevState.period_val,
                                          `${
                                            moment(e)
                                              .format("YYYY-MM")
                                              .split("-")[0]
                                          }-${
                                            moment(e)
                                              .format("YYYY-MM")
                                              .split("-")[1]
                                          }-01`,
                                        ],
                                        loadButtonFlag: 1,
                                      }))
                                    }
                                    disabledDate={(current) => {
                                      return (
                                        current &&
                                        (current <
                                          moment(item.default_start_dt) ||
                                          current >
                                            moment().add(
                                              item.max_month,
                                              "months"
                                            ))
                                      );
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          ))
                        ) : (
                          <>
                            {//this.state.report_config &&
                            // JSON.parse(configdata.report_config).inputtype ==
                            configdata.report_config[0].inputtype ==
                            "Dropdown" ? (
                              <div className="report_inputs pl-1 mb-1">
                                
                                <select
                                  className="form-control text-center"
                                  name={
                                    configdata.report_config[0].inputkey
                                    // JSON.parse(configdata.report_config).inputkey
                                  }
                                  value={
                                    this.state[
                                      configdata.report_config[0].inputkey
                                      // JSON.parse(configdata.report_config).inputkey
                                    ]
                                  }
                                  onChange={(e) =>
                                    this.setState((prevState) => ({
                                      [configdata.report_config[0].inputkey]:
                                        e.target.value,
                                      period_val: [
                                        ...prevState.period_val,
                                        e.target.value,
                                      ],
                                      loadButtonFlag: 1,
                                    }))
                                  }
                                >
                                  <option value="" disabled selected>
                                    {
                                      // JSON.parse(configdata.report_config)
                                      configdata.report_config[0].inputlabel
                                    }
                                  </option>
                                  {// JSON.parse(
                                  //   configdata.report_config)
                                  configdata.report_config[0].inputdata.map(
                                    (reportconfigdata) => (
                                      <option value={reportconfigdata.id}>
                                        {reportconfigdata.datakey}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            ) : configdata.report_config[0].inputtype ==
                              "calendar_yearmonth" ? (
                              <div className="report_inputs pl-1 mb-1">
                                
                                <DatePicker
                                  format={"MMM-YY"}
                                  pickerClass="antd_dateform-control"
                                  picker="month"
                                  placeholder="Select Month"
                                  onChange={(e) =>
                                    this.setState((prevState) => ({
                                      [configdata.report_config[0]
                                        .inputkey]: `${
                                        moment(e)
                                          .format("YYYY-MM")
                                          .split("-")[0]
                                      }-${
                                        moment(e)
                                          .format("YYYY-MM")
                                          .split("-")[1]
                                      }-01`,
                                      period_val: [
                                        ...prevState.period_val,
                                        `${
                                          moment(e)
                                            .format("YYYY-MM")
                                            .split("-")[0]
                                        }-${
                                          moment(e)
                                            .format("YYYY-MM")
                                            .split("-")[1]
                                        }-01`,
                                      ],
                                      loadButtonFlag: 1,
                                    }))
                                  }
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      (current <
                                        moment(
                                          configdata.report_config[0]
                                            .default_start_dt
                                        ) ||
                                        current >
                                          moment().add(
                                            configdata.report_config[0]
                                              .max_month,
                                            "months"
                                          ))
                                    );
                                  }}
                                />
                              </div>
                            ) : configdata.report_config[0].inputtype ==
                              "calendar_yearmonthrange" ? (
                              <div className="report_inputs pl-1 mb-1">
                                
                                <RangePicker
                                  format={"MMM-YY"}
                                  pickerClass="antd_dateform-control"
                                  picker="month"
                                  placeholder={["Start Month", "End Month"]}
                                  onChange={(dates, e) => {
                                    if (dates && dates.length === 2) {
                                      const startDate = moment(dates[0])
                                        .startOf("month")
                                        .format("YYYY-MM-DD");
                                      const endDate = moment(dates[1])
                                        .endOf("month")
                                        .format("YYYY-MM-DD");

                                      this.setState((prevState) => ({
                                        [configdata.report_config[0]
                                          .inputkey]: `${startDate}|${endDate}`,
                                        period_val: [
                                          ...prevState.period_val,
                                          {
                                            id: startDate,
                                            lender_name: startDate,
                                          },
                                          { id: endDate, lender_name: endDate },
                                        ],
                                        loadButtonFlag: 1,
                                      }));
                                    }
                                  }}
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      (current <
                                        moment(
                                          configdata.report_config[0]
                                            .default_start_dt
                                        ) ||
                                        current >
                                          moment().add(
                                            configdata.report_config[0]
                                              .to_max_month,
                                            "months"
                                          ))
                                    );
                                  }}
                                />
                              </div>
                            ) : configdata.report_config[0].inputtype ==
                              "calendar_daterange" ? (
                              <div className="report_inputs pl-1 mb-1">                                
                                <RangePicker
                                  format={"YYYY-MM-DD"}
                                  pickerClass="antd_dateform-control"
                                  placeholder={["Start Date", "End Date"]}
                                  onChange={(dates, e) => {
                                    if (dates && dates.length === 2) {
                                      const startDate = dates[0].format(
                                        "YYYY-MM-DD"
                                      );
                                      const endDate = dates[1].format(
                                        "YYYY-MM-DD"
                                      );
                                      this.setState((prevState) => ({
                                        [configdata.report_config[0]
                                          .inputkey]: `${startDate}|${endDate}`,
                                        period_val: [
                                          ...prevState.period_val,
                                          {
                                            id: startDate,
                                            lender_name: startDate,
                                          },
                                          { id: endDate, lender_name: endDate },
                                        ],
                                        loadButtonFlag: 1,
                                      }));
                                    }
                                  }}
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      (current <
                                        moment(
                                          configdata.report_config[0]
                                            .default_start_dt
                                        ) ||
                                        current >
                                          moment().add(
                                            configdata.report_config[0]
                                              .to_max_month,
                                            "months"
                                          ))
                                    );
                                  }}
                                />
                              </div>
                            ) : configdata.report_config[0].inputtype ==
                              "calendar_date" ? (
                              <div className="report_inputs pl-1 mb-1">
                                <DatePicker
                                  format={"YYYY-MM-DD"}
                                  pickerClass="antd_dateform-control"
                                  placeholder="Select Month"
                                  onChange={(e) =>
                                    this.setState((prevState) => ({
                                      [configdata.report_config[0]
                                        .inputkey]: moment(e).format(
                                        "YYYY-MM-DD"
                                      ),
                                      period_val: [
                                        ...prevState.period_val,
                                        moment(e).format("YYYY-MM-DD"),
                                      ],
                                      loadButtonFlag: 1,
                                    }))
                                  }
                                  disabledDate={(current) => {
                                    return (
                                      current &&
                                      (current <
                                        moment(
                                          configdata.report_config[0]
                                            .default_start_dt
                                        ) ||
                                        current >
                                          moment().add(
                                            configdata.report_config[0]
                                              .max_month,
                                            "months"
                                          ))
                                    );
                                  }}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )

                      // this.state.report_config.map((configdata) => (

                      //   ))
                      }
                      {this.state.report_list &&
                      this.state.period_val &&
                      this.state.loadButtonFlag == 1 ? (
                        <div className="report_inputs">
                          <button
                            className="btn btn-info form-control"
                            onClick={() => {
                              this.setState({
                                isInitial: false,
                                loading: false,
                              });
                              this.loadreport();
                              this.updateslide();
                            }}
                          >
                            Load
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* )} */}
          </div>
          <div className="">
            {console.log("report_url=>", this.state.report_url)}
            {/* {this.state.loading && (
              
            )} */}
            {this.state.report_url && (
              <iframe
                src={this.state.report_url}
                width="100%"
                height="600"
                onLoad={this.hideSpinner}
                ref={this.iframeRef}
                cache="no"
                crossorigin="anonymous"
                Expires="-1"
              ></iframe>
            )}
          </div>
        </div>
      </>
    );
  }
}
export default Mobile_report;
